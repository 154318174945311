// Register ...

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { API_URL } from '../util/ConstValues'
import { ActorStructureClass, RelationshipStructureClass } from '../model/Classes'

import '../style/general.css'
import '../style/elements.css'
import '../style/login.css'

function Register() {

    // locals
    const history = useHistory();
    const [Usr, setUsr] = useState('')
    const [Pwd, setPwd] = useState('')
    const [PwdConfirmation, setPwdConfirmation] = useState('')
    const [Identification, setDocumentoIdentidad] = useState('')
    const [Name, setName] = useState('')
    const [Profession, setProfession] = useState('')
    const [Cargo, setCargo] = useState('')
    const [Country, setCountry] = useState('')
    const [Department, setDepartment] = useState('')
    const [City, setCity] = useState('')
    const [Address, setAddress] = useState('')
    const [CellPhoneNumber, setCellPhoneNumber] = useState('')
    const [terms, setTerms] = useState(false)
    const [isSent, setIsSent] = useState(false)

    // local methods
    const submit = async e => {
        e.preventDefault()

        if (Pwd !== PwdConfirmation) {
            alert('La clave y su confirmación no coinciden, por favor rectifique')
            return
        }

        let actorStructure = new ActorStructureClass();
        actorStructure.profession = Profession;
        actorStructure.country = Country;
        actorStructure.department = Department;
        actorStructure.city = City;
        actorStructure.address = Address;

        let relationshipStructure = new RelationshipStructureClass();
        relationshipStructure.cargo = Cargo;

        const URL = `${API_URL}/User/AddStudent`
        fetch(URL, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Usr, Pwd, Identification, Name, Email: Usr, CellPhoneNumber, actorStructure, relationshipStructure }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    if (data.message.indexOf('duplicate key') >= 0) throw new Error(' Es posible que el email ya se encuentre registrado')
                    else throw new Error(data.message)
                }
                if (data.status && data.status !== 200) throw new Error(data.title)
                console.info('Success:', { NewId: data })
                setIsSent(true)
            })
            .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    }
    const goToLogin = () => {
        history.push('login');
    }
    const goToLanding = () => {
        history.push('landing');
    }

    // local components
    const thankYouMessage =
        <section style={{ height: '50vh' }}>
            <section className="title light-text">Gracias por su regisrto, revisa tu e-mail!</section>
            <section className="buttons">
                <button type="submit" className="main-dark-button" onClick={goToLogin}>Entrar</button>
            </section>
        </section>

    const form =
        <form onSubmit={submit}>
            <section className="forms">
                <section className="field">
                    <label htmlFor="Identification" className="label light-text">Digite su cédula</label>
                    <input id="Identification" name="Identification"
                        value={Identification} onChange={e => setDocumentoIdentidad(e.target.value)}
                        type="number" className="input dark-back-input light-text" required={true}></input>
                </section>
                <section className="field">
                    <label htmlFor="Name" className="label light-text">Digite su nombre</label>
                    <input id="Name" name="Name"
                        value={Name} onChange={e => setName(e.target.value)}
                        type="text" className="input dark-back-input light-text" required={true}></input>
                </section>
                <section className="field">
                    <label htmlFor="Profession" className="label light-text">Profesión</label>
                    <input id="Profession" name="Profession"
                        value={Profession} onChange={e => setProfession(e.target.value)}
                        type="text" className="input dark-back-input light-text" ></input>
                </section>
                <section className="field">
                    <label htmlFor="Cargo" className="label light-text">Cargo que desempeña</label>
                    <input id="Cargo" name="Cargo"
                        value={Cargo} onChange={e => setCargo(e.target.value)}
                        type="text" className="input dark-back-input light-text"  ></input>
                </section>
                <section className="field">
                    <label htmlFor="Country" className="label light-text">Pais</label>
                    <input id="Country" name="Country"
                        value={Country} onChange={e => setCountry(e.target.value)}
                        type="text" className="input dark-back-input light-text" ></input>
                </section>
                <section className="field">
                    <label htmlFor="Department" className="label light-text">Departamento</label>
                    <input id="Department" name="Department"
                        value={Department} onChange={e => setDepartment(e.target.value)}
                        type="text" className="input dark-back-input light-text" ></input>
                </section>
                <section className="field">
                    <label htmlFor="City" className="label light-text">Municipio</label>
                    <input id="City" name="City"
                        value={City} onChange={e => setCity(e.target.value)}
                        type="text" className="input dark-back-input light-text" ></input>
                </section>
                <section className="field">
                    <label htmlFor="Address" className="label light-text">Dirección</label>
                    <input id="Address" name="Address"
                        value={Address} onChange={e => setAddress(e.target.value)}
                        type="text" className="input dark-back-input light-text" ></input>
                </section>
                <section className="field">
                    <label htmlFor="CellPhoneNumber" className="label light-text">Digite su teléfono</label>
                    <input id="CellPhoneNumber" name="CellPhoneNumber"
                        value={CellPhoneNumber} onChange={e => setCellPhoneNumber(e.target.value)}
                        type="text" className="input dark-back-input light-text" required={true}></input>
                </section>
                <section className="field">
                    <label htmlFor="Usr" className="label light-text">Digite su e-mail</label>
                    <input id="Usr" name="Usr"
                        value={Usr} onChange={e => setUsr(e.target.value)}
                        type="text" className="input dark-back-input light-text" required={true}></input>
                </section>
                <section className="field">
                    <label htmlFor="Pwd" className="label light-text">Digite su clave</label>
                    <input id="Pwd" name="Pwd"
                        value={Pwd} onChange={e => setPwd(e.target.value)}
                        type="password" className="input dark-back-input light-text" required={true}></input>
                </section>
                <section className="field">
                    <label htmlFor="PwdConfirmation" className="label light-text">Confirme su clave</label>
                    <input id="PwdConfirmation" name="PwdConfirmation"
                        value={PwdConfirmation} onChange={e => setPwdConfirmation(e.target.value)}
                        type="password" className="input dark-back-input light-text" required={true}></input>
                </section>
                <section className="field">
                    <label htmlFor="terms" className="label light-text">Acepta los terminos del servicio?</label>
                    <input id="terms" name="terms"
                        value={terms} onChange={e => setTerms(e.target.checked)}
                        type="checkbox" className="input dark-back-input light-text" required={true}></input>
                    <a href="/condiciones-y-politicas.txt" className="link light-text">Condiciones y politicas</a>
                </section>
            </section>
            <section className="buttons">
                <button type="submit" className="main-dark-button">Registrarse</button>
                <button type="button" className="light-button" onClick={goToLanding}>Volver</button>
            </section>
        </form>

    return (
        <section className="dark">
            <section className="header-product" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
                <img src="logo-blanco.png" className="logo" alt="logo"></img>
            </section>
            <section className="title title-font big-text light-text" style={{ marginTop: '5vh', marginBottom: '5vh' }}>
                Le da la bienvenida
            </section>
            {isSent ? thankYouMessage : form}
        </section>
    )
}

export default Register
