// Answers ...

import React, { useState, useEffect, useCallback } from "react"

import { useGlobalState } from '../util/State'

import '../style/general.css'
import '../style/elements.css'

function Answers(props) {

  const { questionId, answer, setAnswer } = props

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const getUniqueId = () => {
    return `${userInfo.sessionId}.${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}.${new Date().getHours()}.${new Date().getMinutes()}.${new Date().getSeconds()}.${new Date().getMilliseconds()}`;
  }
  const [editingAnswer, setEditingAnswer] = useState({ Id: getUniqueId(), Text: '', IsCorrect: false, Order: 0, Value: 0 })
  const [showingList, setShowingList] = useState(true)
  const [details, setDetails] = useState([])

  const getQuestionAnswers = useCallback(() => {

    let definition = answer.structure.find(x => x.questionId === questionId)
    if (definition == null) {
      definition = { questionId: questionId, answers: [] }
      answer.structure = [...answer.structure, definition]
    }
    return definition.answers.sort((a, b) => a.order - b.order)
  }, [answer, questionId])

  useEffect(() => {
    setDetails(getQuestionAnswers())
  }, [getQuestionAnswers])

  // local methods    
  const edit = async id => {

    const answerLocal = details.find(x => x.id === id)
    setEditingAnswer(answerLocal == null ? { id: getUniqueId(), text: '', isCorrect: false, order: 0, value: 0 } : answerLocal)
    setShowingList(false)
  }
  const setFieldChange = async (e) => {

    const target = e.target;
    const value = (target.type === 'checkbox' ? target.checked : (target.type === 'number' ? parseFloat(target.value) : target.value))
    const name = target.name;
    setEditingAnswer({ ...editingAnswer, [name]: value });
  }
  const save = async e => {
    e.preventDefault()
    const answerIdx = details.findIndex(x => x.id === editingAnswer.id)
    details.splice(answerIdx, (answerIdx >= 0 ? 1 : 0), editingAnswer)
    const repeatedOrders = details.map(m => m.order).filter((e, i, a) => a.indexOf(e) !== i)
    if (repeatedOrders.length > 0) {
      alert('El orden de cada respuesta debe ser único')
      return
    }
    const answersFiltered = details.filter(x => x.text.trim() !== '' && x.order > 0 && x.value > 0)
    const answerRoot = { ...answer }
    answerRoot.structure.find(x => x.questionId === questionId).answers = answersFiltered
    setAnswer(answerRoot)
    setShowingList(true)
  }
  const add = async => {
    edit('');
  }

  // local components
  const tableRows = details.map((row) =>
    <tr key={row.id} onClick={e => edit(row.id)}>
      <td> {row.text} </td>
      <td> {row.isCorrect ? 'Si' : 'No'} </td>
      <td> {row.order} </td>
      <td> {row.value} </td>
    </tr>
  )
  const answersTable =
    <>
      <section className="module">
        <label className="title-font">Listado de respuestas</label>
      </section>
      <table className="sub-table">
        <thead>
          <tr>
            <th>Respuesta</th>
            <th>Es correcta?</th>
            <th>Orden</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <button type="button" className="mini-button alternate-button" title="Nueva respuesta" onClick={() => add()}>&#10010;</button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>

  const editForm =
    <>
      <form onSubmit={save} style={{ backgroundColor: "var(--pure-white)" }}>
        <section className="module">
          <label className="title-font">Edición respuesta</label>
        </section>
        <section className="sub-forms">
          <section className="field">
            <input id="text" name="text"
              value={editingAnswer.text} onChange={setFieldChange}
              type="text" className="input" required={true} placeholder="Respuesta"></input>
          </section>
          <section className="field">
            <label htmlFor="isCorrect" >Es correcta?</label>
            <input id="isCorrect" name="isCorrect"
              value={editingAnswer.isCorrect} onChange={setFieldChange}
              type="checkbox" className="input" title="Es correcta"></input>
          </section>
          <section className="field">
            <input id="order" name="order"
              value={editingAnswer.order} onChange={setFieldChange}
              type="number" className="input" required={true} placeholder="Orden de aparición" title="Orden de aparición"></input>
          </section>
          <section className="field">
            <input id="value" name="value"
              value={editingAnswer.value} onChange={setFieldChange}
              type="number" className="input" required={true} placeholder="Valor" title="Valor"
              min="10" max="100" minLength="2" maxLength="3" ></input>
          </section>
        </section>

        <section className="sub-buttons">
          <button type="button" className="mini-button alternate-button" onClick={() => setShowingList(true)} title="Lista">&#10094;</button>
          <button type="submit" className="mini-button" title="Guardar">&#10004;</button>
        </section>
      </form>
    </>

  return (
    <>
      {showingList ? answersTable : editForm}
    </>
  )
}

export default Answers
