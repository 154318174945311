// Home ...

import React from 'react'
import { BrowserRouter, Route, Link, Redirect, Switch } from 'react-router-dom';

import { useGlobalState } from './util/State';

// app
import Landing from './app/Landing'
import Register from './app/Register'
import ConfirmRegister from './app/ConfirmRegister'
import Users from './/app/Users'
import Login from './app/Login'
import Main from './app/Main'
import Menu from './app/Menu'
import Exit from './app/Exit'

// questionnaire
import Questionnairies from './questionnaire/Questionnairies'

// task
import Task from './task/Tasks'
import TasksOfStudent from './task/TasksOfStudent'

// catalog
import Catalog from './catalog/Catalog'
import Catalogs from './catalog/Catalogs'

// process
import Process from './process/Process'
import LevelAdvance from './process/LevelAdvance'

// calendar
import Calendar from './calendar/Calendar'
import CalendarForUser from './calendar/CalendarForUser'

// diagnostic
import TextForPlan from './diagnostic/TextForPlan'
import Diagnostic from './diagnostic/Diagnostic'
import Score from './diagnostic/Score'
import DiagnosticsForActivateConsulting from './diagnostic/DiagnosticsForActivateConsulting'

import './style/base.css'
import './style/app.css'

function App() {

  // globals
  const [showMenu, setShowMenu] = useGlobalState('showMenu');
  const [showMenuButton,] = useGlobalState('showMenuButton')

  // local methods
  const toogleMenu = () => setShowMenu(!showMenu)

  // local components
  const goHomeButton =
    <Link className="home-button" href="#"
      to={showMenuButton ? "/main" : "/landing"}>
      <img src="logo-horizontal.png" alt="logo"></img>
    </Link>

  const menuSymbol =
    <div className="om menu-button" onClick={toogleMenu}>
      <img src="ico-hamburguesa.png" alt="menu"></img>
    </div>

  const vacio = <></>

  return (
    <BrowserRouter>

      <div className="body">

        {showMenuButton ?
          <header> {goHomeButton} {menuSymbol}
            <div className="menu-od">
              <Menu toogleMenu={toogleMenu} ></Menu>
            </div>
          </header>
          : vacio}

        {showMenu ?
          <div className="menu-om">
            <Menu toogleMenu={toogleMenu} ></Menu>
          </div>
          : vacio}

        <Switch>

          <main>

            <Route path="/landing" component={Landing} />
            <Route path="/register" component={Register} />
            <Route path='/confirmRegister' component={ConfirmRegister} />
            <Route path='/login' component={Login} />
            <Route path="/main" component={Main} />
            <Route path='/exit' render={(props) => <Exit  {...props} toogleMenu={toogleMenu} />} />
            <Route path='/users' component={Users} />
            <Route path='/questionnairies' component={Questionnairies} />
            <Route path='/diagnostic' component={Diagnostic} />
            <Route path='/score' component={Score} />
            <Route path='/textForPlan' component={TextForPlan} />
            <Route path='/diagnosticsForActivateConsulting' component={DiagnosticsForActivateConsulting} />
            <Route path='/task' component={Task} />
            <Route path='/taskOfStudent' component={TasksOfStudent} />
            <Route path='/catalog' component={Catalog} />
            <Route path='/process' component={Process} />
            <Route path='/levelAdvance' component={LevelAdvance} />
            <Route path='/calendar' component={Calendar} />
            <Route path='/catalogs' component={Catalogs} />
            <Route path='/calendarForUser' component={CalendarForUser} />

            <Redirect from="/" to="/landing"></Redirect>

          </main>

          <footer> &copy; 2020 </footer>
        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default App
