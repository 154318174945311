// Catalogs for ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import '../style/general.css'
import '../style/elements.css'
import '../style/card.css'

function Catalogs() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [catalogs, setCatalogs] = useState([])

  // file handle methods   
  const getImages = useCallback(async (catalogs) => {
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    try {
      for (let idx = 0; idx < catalogs.length; idx++) {
        const catalog = catalogs[idx]
        const fileId = catalog.imagePath
        const ENDPOINT_URL = `${API_URL}/file/get/?fileId=${fileId}`
        const response = await fetch(ENDPOINT_URL, { method: 'GET', headers: headers })
        const data = await response.blob()
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        const img = { fileId: fileId, name: '', image: null, preview: URL.createObjectURL(data) }
        catalog.img = img
      }
      setCatalogs(catalogs)
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }, [userInfo])

  // local methods   
  const listCatalogs = useCallback(() => {
    // call api to list catalogs
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Catalog/listFor`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        getImages(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo, getImages])

  // local components  
  let rowIdx = 0
  const listItems = catalogs.map((catalogRow) => {
    const cardColor = (rowIdx % 2 === 0 ? 'even' : 'odd')
    const blockClass = `item-card card-color-${cardColor}`
    const blockDescriptionClass = `item-card-description card-description-color-${cardColor}`
    rowIdx++
    return (
      <section className="item-card-with-image" key={catalogRow.id}>
        <section className="item-card-image">
          {(catalogRow.img && catalogRow.img.preview) ? (<img src={catalogRow.img.preview} alt="representación del catálogo" />) : ''}
        </section>
        <section className={blockClass}>
          <section className="item-card-id">Cuestionario:</section>
          <section className="item-card-questionnaire">{catalogRow.questionnaire}</section>
          <section className="item-card-id">Catálogo #{catalogRow.id}</section>
          <section className={blockDescriptionClass}>{catalogRow.description}</section>
        </section>
      </section>)
  })

  const list =
    <>
      <section className="module">
        <img alt="Catálogo" src="ico-catalogo.png"></img>
        <label className="title-font big-text">Listado de catálogos</label>
      </section>
      <section className="item-cards">
        {listItems}
      </section>
    </>

  useEffect(() => {
    listCatalogs()
  }, [listCatalogs])

  return (
    <>
      {list}
    </>
  )
}

export default Catalogs
