// TextForPlan ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL, RecordStatus } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import '../style/general.css'
import '../style/elements.css'

function TextForPlan() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [showingList, setShowingList] = useState(true)
  const [questionnairies, setQuestionnairies] = useState([])
  const [texts, setTexts] = useState([])
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState(-1)
  const [text, setText] = useState({ id: -1, questionnaireId: currentQuestionnaireId, rangeMinValue: 0, rangeMaxValue: 0, rangeText: '', status: RecordStatus.Activo })

  // local methods 
  const listQuestionnairies = useCallback(() => {
    // call api to list questionnairies
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Questionnaire/list`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setQuestionnairies(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])

  const listTexts = async (questionnaireId) => {
    setCurrentQuestionnaireId(parseInt(questionnaireId))
    // call api to list texts
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const qs = `questionnaireId=${questionnaireId}`
    const URL = `${API_URL}/TextForPlan/list/?${qs}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setTexts(data)
        setShowingList(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }

  const editItem = async id => {
    let textForPlanLocal = texts.find(x => x.id === id)
    if (textForPlanLocal == null) {
      textForPlanLocal = { id: -1, questionnaireId: currentQuestionnaireId, rangeMinValue: 0, rangeMaxValue: 0, rangeText: '', status: RecordStatus.Activo }
    }
    setText(textForPlanLocal)
    setShowingList(false)
  }

  const setFormFieldChange = async (e) => {
    const target = e.target
    const value = (target.type === 'checkbox' ? target.checked : (target.type === 'number' ? parseFloat(target.value) : target.value))
    const name = target.name
    setText({ ...text, [name]: value })
  }

  const submit = async e => {
    e.preventDefault()

    if (!window.confirm('Confirma los cambios que acaba de realizar?')) return

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/TextForPlan/put`
    fetch(URL, { method: 'PUT', headers: headers, body: JSON.stringify(text) })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        listTexts(text.questionnaireId)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }

  const addNewTextForPlan = async => {
    if (currentQuestionnaireId == null || currentQuestionnaireId === -1) {
      alert('No ha elegido un cuestionario')
      return
    }
    editItem(-1)
  }

  const getStatusName = statusId =>
    Object.entries(RecordStatus).find(x => x[1] === statusId)[0]

  // local components
  const questionnaireOptions = questionnairies.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.name}</option>
    )
  })

  const listItems = texts.map((textForPlanRow) =>
    <tr key={textForPlanRow.id} onClick={e => editItem(textForPlanRow.id)}>
      <td>{textForPlanRow.rangeMinValue}</td>
      <td>{textForPlanRow.rangeMaxValue}</td>
      <td className="od">{textForPlanRow.rangeText}</td>
      <td className="od">{getStatusName(textForPlanRow.status)}</td>
    </tr>
  )

  const list =
    <>
      <section className="module">
        <img alt="Texto" src="ico-texto.png"></img>
        <label className="title-font big-text">Listado de textos para el plan a seguir</label>
      </section>
      <>
        <select id="currentQuestionnaire" name="currentQuestionnaire"
          value={currentQuestionnaireId} onChange={e => { listTexts(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un cuestionario</option>
          {questionnaireOptions}
        </select>
      </>
      <table>
        <thead>
          <tr>
            <th>Rango mínimo</th>
            <th>Rango maximo</th>
            <th className="od">Texto</th>
            <th className="od">Estado</th>
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <button type="button" className="alternate-button" onClick={() => addNewTextForPlan()} title="Nuevo texto">&#10010;</button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>

  const statusItems = Object.entries(RecordStatus).map(e => {
    return (
      <option key={e[1]} value={e[1]}>{e[0]}</option>
    )
  })

  const edit =
    <form onSubmit={submit} style={{ display: 'contents' }}>
      <section className="module">
        <img alt="Texto" src="ico-texto.png"></img>
        <label className="title-font big-text">Edición texto</label>
      </section>
      <section className="forms">
        <section className="field">
          <label htmlFor="rangeMinValue" >Rango mínimo</label>
          <input id="rangeMinValue" name="rangeMinValue"
            value={text.rangeMinValue} onChange={setFormFieldChange}
            type="number" min={10} max={100} className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="rangeMaxValue" >Rango maximo</label>
          <input id="rangeMaxValue" name="rangeMaxValue"
            value={text.rangeMaxValue} onChange={setFormFieldChange}
            type="number" min={10} max={100} className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="rangeText" >Texto</label>
          <input id="rangeText" name="rangeText"
            value={text.rangeText} onChange={setFormFieldChange}
            type="text" className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="status" >Estado</label>
          <select id="status" name="status"
            value={text.status} onChange={setFormFieldChange}
            className="input" required={true}>
            {statusItems}
          </select>
        </section>

      </section>

      <section className="buttons">
        <button type="button" className="alternate-button" onClick={() => setShowingList(true)}>Volver</button>
        <button type="submit" >Guardar</button>
      </section>
    </form>

  useEffect(() => {
    listQuestionnairies()
  }, [listQuestionnairies])

  return (
    <>
      {showingList ? list : edit}
    </>
  )
}

export default TextForPlan
