// Regostro ...

import React, { useEffect, useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom';

import { useGlobalState } from '../util/State'
import { API_URL } from '../util/ConstValues'

import '../style/general.css'
import '../style/elements.css'
import '../style/login.css'

function Login() {

    // globals
    const [userInfo, setUserInfo] = useGlobalState('userInfo')
    const [, setShowMenuButton] = useGlobalState('showMenuButton')

    // locals
    const history = useHistory();
    const [Usr, setUsr] = useState('')
    const [Pwd, setPwd] = useState('')
    const [ConfirmPwd, setConfirmPwd] = useState('')
    const [Code, setCode] = useState('')
    const [isSent, setIsSent] = useState(false)
    const [isChangePwd, setIsChangePwd] = useState(false)


    // local methods
    const submit = async e => {
        e.preventDefault()

        const URL = `${API_URL}/Security/authenticate`
        fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Usr, Pwd }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) throw new Error(data.message)
                if (data.status && data.status !== 200) throw new Error(data.title)
                setUserInfo(data[0])
                setIsSent(true)
                setShowMenuButton(true)
            })
            .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    }
    const sendForgottenPassword = async () => {
        try {
            if (Usr.trim() === '') throw new Error('Debe especificar el e-mail que uso para su registro')

            const URL = `${API_URL}/Security/sendForgottenPassword`
            const response = await fetch(URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Usr })
            });
            const data = await response.json()
            if (data.message) throw new Error(data.message)
            if (data) alert('Revise su e-mail')
            setIsChangePwd(true)
        } catch (error) {
            alert(`Revise y reintente. ${error}`)
        }
    }
    const changePwd = async (e) => {
        e.preventDefault()

        if (Pwd !== ConfirmPwd) {
            alert('La clave y la confirmación deben ser identicas')
            return
        }
        const URL = `${API_URL}/Security/resetPassword`;
        fetch(URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Code, Pwd, ConfirmPwd })
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) throw new Error(data.message)
                if (data.status && data.status !== 200) throw new Error(data.title)
                setIsChangePwd(false)
            })
            .catch((error) => alert(`Por favor verifique y reintente. ${error}`))
    }
    const goToRegister = () => {
        history.push('register');
    }

    useEffect(() => {
        if (userInfo.roles != null) {
            setIsSent(true)
        }
    }, [userInfo.roles])

    const loginForm =
        <form onSubmit={submit}>
            <section className="forms one-column-form">
                <section className="field">
                    <input id="Usr" name="Usr" placeholder="Usuario"
                        value={Usr} onChange={e => setUsr(e.target.value)}
                        type="text" className="user-input dark-back-input light-text" required={true}></input>
                </section>
                <section className="field">
                    <input id="Pwd" name="Pwd" placeholder="Clave"
                        value={Pwd} onChange={e => setPwd(e.target.value)}
                        type="password" className="pwd-input dark-back-input light-text" required={true}></input>
                </section>
            </section>
            <section className="buttons">
                <button type="submit" className="main-dark-button">Iniciar sesión</button>
            </section>
            <section className="buttons" style={{ marginTop: '5vh', marginBottom: '5vh' }}>
                <label className="label light-text">¿Ha olvidado su clave?</label>
                <Link className="label light-text link right" to="#" onClick={sendForgottenPassword} >Recuperela aquí</Link>
            </section>
            <section className="buttons">
                <button type="button" className="light-button" onClick={goToRegister}>Registrarse</button>
            </section>
        </form>

    const changePwdForm =
        <form onSubmit={changePwd}>
            <section className="forms one-column-form">
                <section className="field">
                    <input id="Code" name="Code" placeholder="Digite el código"
                        value={Code} onChange={e => setCode(e.target.value)}
                        type="text" className="pwd-input dark-back-input light-text" ></input>
                </section>
                <section className="field">
                    <input id="Pwd" name="Pwd" placeholder="Digite su nueva clave"
                        value={Pwd} onChange={e => setPwd(e.target.value)}
                        type="password" className="pwd-input dark-back-input light-text" ></input>
                </section>
                <section className="field">
                    <input id="ConfirmPwd" name="ConfirmPwd" placeholder="Confirme su nueva clave"
                        value={ConfirmPwd} onChange={e => setConfirmPwd(e.target.value)}
                        type="password" className="pwd-input dark-back-input light-text" ></input>
                </section>
            </section>
            <section className="buttons">
                <button type="submit" className="main-dark-button">Cambiar clave</button>
            </section>
        </form>

    return (
        <section className="dark">
            <section className="header-product" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
                <img src="logo-blanco.png" className="logo" alt="logo"></img>
            </section>
            {isSent ? <Redirect from="/login" to="main" /> : (isChangePwd ? changePwdForm : loginForm)}
        </section>
    )
}

export default Login
