// Calendar ...

import React, { useState, useEffect, useCallback } from "react"

import { useGlobalState } from '../util/State';
import { API_URL, RecordStatus } from '../util/ConstValues'

import '../style/general.css'
import '../style/elements.css'

function Calendar() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [questionnairies, setQuestionnairies] = useState([])
  const [catalogs, setCatalogs] = useState([])
  const [processes, setProcesses] = useState([])
  const [students, setStudents] = useState([])
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState(-1)
  const [currentCatalogId, setCurrentCatalogId] = useState(-1)
  const [currentProcessId, setCurrentProcessId] = useState(-1)
  const [currentUserId, setCurrentUserId] = useState(-1)
  const [currentUser, setCurrentUser] = useState(-1)
  const [calendars, setCalendars] = useState([])
  const [calendar, setCalendar] = useState({
    id: -1, userId: currentUserId, user: currentUser, adminId: -1, processId: currentProcessId, descriptionOfTheCommitment: '',
    dateTime: new Date(), status: RecordStatus.Activo, calendarStructure: {}
  })
  const [showCalendars, setShowCalendars] = useState(false)
  const [showCalendarEditor, setShowCalendarEditor] = useState(false)

  // form fields
  const [descriptionOfTheCommitment, setDescriptionOfTheCommitment] = useState('')
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10))
  const [time, setTime] = useState(new Date().toISOString().substring(11, 16))

  // local methods 
  const submit = async (e) => {
    e.preventDefault()

    if (!window.confirm('Confirma los cambios que acaba de realizar?')) return

    calendar.descriptionOfTheCommitment = descriptionOfTheCommitment
    calendar.dateTime = new Date(`${date} ${time}:00`).toISOString()

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Calendar/put`
    fetch(URL, { method: 'PUT', headers: headers, body: JSON.stringify(calendar) })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setShowCalendarEditor(false)
        listCalendar(currentProcessId, currentUserId, currentUser)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }
  const editItem = async id => {
    let calendarLocal = calendars.find(x => x.id === id)
    if (calendarLocal == null) {
      calendarLocal = {
        id: -1, userId: currentUserId, user: currentUser, adminId: -1, processId: currentProcessId, descriptionOfTheCommitment: '',
        dateTime: new Date(), status: RecordStatus.Activo, calendarStructure: {}
      }
    }

    if (!calendarLocal.user) calendarLocal.user = currentUser
    setCalendar(calendarLocal)

    // form fields    
    setDescriptionOfTheCommitment(calendarLocal.descriptionOfTheCommitment)
    setDate(new Date(calendarLocal.dateTime).toISOString().substring(0, 10))
    setTime(new Date(calendarLocal.dateTime).toISOString().substring(11, 16))

    setShowCalendars(false)
    setShowCalendarEditor(true)
  }
  const addNewItem = async => {
    if (currentProcessId == null || currentProcessId === -1) {
      alert('No ha elegido un proceso')
      return
    }
    editItem(-1)
  }

  const listCalendar = async (processId, userId, user) => {
    setCurrentUserId(parseInt(userId))
    setCurrentUser(user)
    // call api to list steps
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Calendar/list/?processId=${processId}&userId=${userId}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setCalendars(data)
        setShowCalendars(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listStudents = async (processId) => {
    setCurrentProcessId(parseInt(processId))
    // call api to list steps
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Calendar/listStudents/?processId=${processId}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setStudents(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listProcess = async (catalogId) => {
    setCurrentCatalogId(parseInt(catalogId))
    // call api to list processes
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Process/list/?catalogId=${catalogId}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setProcesses(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listCatalogs = async (questionnaireId) => {
    setCurrentQuestionnaireId(parseInt(questionnaireId))
    // call api to list processes
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const qs = `questionnaireId=${questionnaireId}`
    const URL = `${API_URL}/Catalog/list/?${qs}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setCatalogs(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listQuestionnairies = useCallback(() => {
    // call api to list questionnairies
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Questionnaire/list`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setQuestionnairies(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])

  useEffect(() => {
    listQuestionnairies()
  }, [listQuestionnairies])

  // local components
  const questionnaireOptions = questionnairies.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.name}</option>
    )
  })
  const catalogOptions = catalogs.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.description}</option>
    )
  })
  const processOptions = processes.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.name}</option>
    )
  })

  const rowLevelComp = (rowLevel) => {

    const process = processes.find(x => x.id === currentProcessId)

    if (process == null) return (<></>) // is no process there
    if (process.structure == null) return (<></>) // is no structure there
    if (process.structure.length < 2) return (<></>) // is no steps enough 

    process.structure.forEach(x => x.order = parseInt(x.order))
    const sortedProcessSteps = process.structure.sort((a, b) => a.order - b.order)

    const firstStep = sortedProcessSteps[0]
    const lasttStep = sortedProcessSteps[sortedProcessSteps.length - 1]

    const scoreBarWidth = 100
    const scorePositionLoc = scoreBarWidth * (rowLevel / lasttStep.order)
    const scorePosition = `${scorePositionLoc}px`

    return (
      <div className="mini-score-bar-container">
        <img className="mini-signaler" style={{ left: scorePosition }} src="ico-flecha.png" alt="Señalador"></img>
        <div className="mini-score-bar"> {rowLevel} </div>
        <div className="mini-score-bar-values">
          <div className="mini-score-bar-min"> {firstStep.order} </div>
          <div className="mini-score-bar-max"> {lasttStep.order} </div>
        </div>
      </div>)
  }

  let itemIdx = 0
  const studentListItems = students.map((row) => {
    itemIdx++
    return (
      <tr key={itemIdx} >
        <td>{row.user}</td>
        <td className="od">{row.admin}</td>
        <td>{rowLevelComp(row.order)}</td>
        <td className="od">{row.updated}</td>
        <td> <button style={{ maxWidth: '100px', minWidth: '100px' }} onClick={e => listCalendar(row.processId, row.userId, row.user)}>Ver calendario</button> </td>
      </tr>)
  })

  const studentList =
    <>
      <section className="module">
        <img alt="Proceso" src="ico-calendario.png"></img>
        <label className="title-font big-text">Calendario</label>
      </section>
      <>
        <select id="currentQuestionnaire" name="currentQuestionnaire"
          value={currentQuestionnaireId} onChange={e => { listCatalogs(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un cuestionario</option>
          {questionnaireOptions}
        </select>

        <select id="currentCatalog" name="currentCatalog"
          value={currentCatalogId} onChange={e => { listProcess(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un catálogo</option>
          {catalogOptions}
        </select>

        <select id="currentProcess" name="currentProcess"
          value={currentProcessId} onChange={e => { listStudents(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un proceso</option>
          {processOptions}
        </select>
      </>
      <table>
        <thead>
          <tr>
            <th>Usuario</th>
            <th className="od">Admin</th>
            <th>Nivel</th>
            <th className="od">Fecha</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {studentListItems}
        </tbody>
      </table>
    </>

  const calendarListItems = calendars.map((row) => {
    return (
      <tr key={row.id} onClick={(e) => { editItem(row.id) }}>
        <td>{row.descriptionOfTheCommitment}</td>
        <td>{row.dateTime}</td>
        <td className="od">{row.updated}</td>
      </tr>)
  })


  const calendarList =
    <>
      <section className="module">
        <img alt="Proceso" src="ico-calendario.png"></img>
        <label className="title-font big-text">Calendario</label>
      </section>
      <section className="module">
        <label className="title-font">Usuario</label>
        <label className="title-font">{currentUser}</label>
      </section>
      <table>
        <thead>
          <tr>
            <th>Actividad</th>
            <th>Fecha y hora</th>
            <th className="od">Programado</th>
          </tr>
        </thead>
        <tbody>
          {calendarListItems}
        </tbody>
        <tfoot>
          <tr>
            <td> <button className="alternate-button" onClick={e => setShowCalendars(false)}>Volver</button> </td>
            <td className="od"></td>
            <td className="right"> <button className="button" onClick={e => addNewItem()} title="Nuevo evento" >&#10010;</button> </td>
          </tr>
        </tfoot>
      </table>
    </>

  const calendarEditor =
    <form onSubmit={submit} style={{ display: 'contents' }}>
      <section className="module">
        <img alt="Proceso" src="ico-calendario.png"></img>
        <label className="title-font big-text">Calendario</label>
      </section>
      <section className="forms">

        <section className="field">
          <label htmlFor="description" >Descripción de la actividad</label>
          <input id="description" name="description" defaultValue={descriptionOfTheCommitment}
            onChange={e => setDescriptionOfTheCommitment(e.target.value)} type="text" className="input" required={true}></input>
        </section>

        <section className="field">
          <label htmlFor="activity-date" >Fecha</label>
          <input id="activity-date" name="activity-date" defaultValue={date}
            onChange={e => setDate(e.target.value)} type="date" className="input" required={true}></input>
        </section>

        <section className="field">
          <label htmlFor="activity-time" >Hora</label>
          <input id="activity-time" name="activity-time" defaultValue={time}
            onChange={e => setTime(e.target.value)} type="time" className="input" required={true}></input>
        </section>

      </section>

      <section className="buttons">
        <button type="button" className="alternate-button" onClick={() => { setShowCalendarEditor(false); setShowCalendars(true); }}>Volver</button>
        <button type="submit" >Guardar</button>
      </section>
    </form>

  return (
    <>
      { showCalendarEditor ? calendarEditor :
        (showCalendars ? calendarList : studentList)}
    </>
  )
}

export default Calendar
