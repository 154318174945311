// LevelAdvance ...

import React, { useState, useEffect, useCallback } from "react"

import { useGlobalState } from '../util/State';
import { API_URL } from '../util/ConstValues'

import '../style/general.css'
import '../style/elements.css'
import '../style/score.css'

function LevelAdvance() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [questionnairies, setQuestionnairies] = useState([])
  const [catalogs, setCatalogs] = useState([])
  const [processes, setProcesses] = useState([])
  const [steps, setSteps] = useState([])
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState(-1)
  const [currentCatalogId, setCurrentCatalogId] = useState(-1)
  const [currentProcessId, setCurrentProcessId] = useState(-1)

  // local methods 
  const put = async (step) => {
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Step/put`
    fetch(URL, { method: 'PUT', headers: headers, body: JSON.stringify(step) })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        listSteps(currentProcessId)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }
  const start = async (userId, user) => {
    if (!window.confirm('Confirma el inicio del proceso para este usuario?')) return
    const step = { id: -1, userId: userId, user: user, adminId: userInfo.UserId, processId: currentProcessId, description: '', order: 1, userLog: userInfo.UserId, updated: '1900-01-01', structure: {} }
    put(step)
  }
  const forward = async (step) => {
    const process = processes.find(x => x.id === step.processId)
    process.structure.forEach(x => x.order = parseInt(x.order))
    const sortedProcessSteps = process.structure.sort((a, b) => a.order - b.order)
    const processStep = sortedProcessSteps.find(o => o.order === step.order + 1)
    if (processStep == null) {
      alert('No hay mas niveles para este proceso')
      return
    }
    if (!window.confirm('Confirma el avance de nivel para este usuario?')) return
    step.order++
    step.adminId = userInfo.UserId
    step.userLog = userInfo.UserId
    put(step)
  }

  const listSteps = async (processId) => {
    setCurrentProcessId(parseInt(processId))
    // call api to list steps
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Step/list/?processId=${processId}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setSteps(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listProcess = async (catalogId) => {
    setCurrentCatalogId(parseInt(catalogId))
    // call api to list processes
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Process/list/?catalogId=${catalogId}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setProcesses(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listCatalogs = async (questionnaireId) => {
    setCurrentQuestionnaireId(parseInt(questionnaireId))
    // call api to list processes
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const qs = `questionnaireId=${questionnaireId}`
    const URL = `${API_URL}/Catalog/list/?${qs}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setCatalogs(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listQuestionnairies = useCallback(() => {
    // call api to list questionnairies
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Questionnaire/list`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setQuestionnairies(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])

  useEffect(() => {
    listQuestionnairies()
  }, [listQuestionnairies])

  // local components
  const questionnaireOptions = questionnairies.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.name}</option>
    )
  })
  const catalogOptions = catalogs.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.description}</option>
    )
  })
  const processOptions = processes.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.name}</option>
    )
  })

  const rowLevelComp = (rowLevel) => {

    const process = processes.find(x => x.id === currentProcessId)

    if (process == null) return (<></>) // is no process there
    if (process.structure == null) return (<></>) // is no structure there
    if (process.structure.length < 2) return (<></>) // is no steps enough 

    process.structure.forEach(x => x.order = parseInt(x.order))
    const sortedProcessSteps = process.structure.sort((a, b) => a.order - b.order)

    const firstStep = sortedProcessSteps[0]
    const lasttStep = sortedProcessSteps[sortedProcessSteps.length - 1]

    const scoreBarWidth = 100
    const scorePositionLoc = scoreBarWidth * (rowLevel / lasttStep.order)
    const scorePosition = `${scorePositionLoc}px`

    return (
      <div className="mini-score-bar-container">
        <img className="mini-signaler" style={{ left: scorePosition }} src="ico-flecha.png" alt="Señalador"></img>
        <div className="mini-score-bar"> {rowLevel} </div>
        <div className="mini-score-bar-values">
          <div className="mini-score-bar-min"> {firstStep.order} </div>
          <div className="mini-score-bar-max"> {lasttStep.order} </div>
        </div>
      </div>)
  }

  let itemIdx = 0
  const listItems = steps.map((row) => {
    itemIdx++
    return (
      <tr key={itemIdx} >
        <td>{row.user}</td>
        <td className="od">{row.admin}</td>
        <td>{rowLevelComp(row.order)}</td>
        <td className="od">{row.updated}</td>
        <td>
          {row.processId === -1 ?
            <button style={{ maxWidth: '100px', minWidth: '100px' }} onClick={e => start(row.userId, row.user)}>Iniciar</button> :
            <button style={{ maxWidth: '100px', minWidth: '100px' }} onClick={e => forward(row)}>Avanzar</button>}
        </td>
      </tr>)
  })

  const list =
    <>
      <section className="module">
        <img alt="Proceso" src="ico-proceso.png"></img>
        <label className="title-font big-text">Avance de nivel</label>
      </section>
      <section className="boxes">
        <select id="currentQuestionnaire" name="currentQuestionnaire"
          value={currentQuestionnaireId} onChange={e => { listCatalogs(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un cuestionario</option>
          {questionnaireOptions}
        </select>

        <select id="currentCatalog" name="currentCatalog"
          value={currentCatalogId} onChange={e => { listProcess(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un catálogo</option>
          {catalogOptions}
        </select>

        <select id="currentProcess" name="currentProcess"
          value={currentProcessId} onChange={e => { listSteps(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un proceso</option>
          {processOptions}
        </select>
      </section>
      <table>
        <thead>
          <tr>
            <th>Usuario</th>
            <th className="od">Admin</th>
            <th>Nivel</th>
            <th className="od">Fecha</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
      </table>
    </>

  return (
    <>
      {list}
    </>
  )
}

export default LevelAdvance
