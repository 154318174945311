// ConfirmRegister ...

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { API_URL } from '../util/ConstValues'

const useQuery = () => new URLSearchParams(useLocation().search);

function ConfirmRegister() {

    // locals
    const query = useQuery();
    const userId = parseInt(query.get("id"))

    useEffect(() => {
        if (userId == null || typeof (userId) != 'number') return
        (async () => {
            const URL = `${API_URL}/User/ConfirmRegister`
            try {
                const response = await fetch(URL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: userId })
                const data = await response.json()
                if (data.message) throw new Error(data.message)
                else if (data.status && data.status !== 200) throw new Error(data.title)             
                alert('Listo, se completo tu registro')
            } catch (error) {
                alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
            }
        })()
    })

    return (<> </>)
}

export default ConfirmRegister
