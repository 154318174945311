// Menu ...

import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { useGlobalState } from '../util/State'

import '../style/elements.css'
import '../style/menu.css'

function Menu(props) {
  const { toogleMenu } = props;

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const roles = (userInfo && userInfo.roles ? userInfo.roles.split(',') : [])
  const [goExit, setGoExit] = useState(false)

  // local components
  const usersMenu =
    <Link className="menu-option" to="/users" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Usuarios" src="ico-usuario-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Usuarios</label></section>
    </Link>

  const questionnairiesMenu =
    <Link className="menu-option" to="/questionnairies" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Cuestionarios" src="ico-cuestionario-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Cuestionarios</label></section>
    </Link>

  const textForPlanMenu =
    <Link className="menu-option" to="/textForPlan" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Texto del plan" src="ico-texto-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Textos</label></section>
    </Link>

  const diagnosticsForActivateConsulting =
    <Link className="menu-option" to="/diagnosticsForActivateConsulting" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Activador de consultoría" src="ico-activar-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Activador</label></section>
    </Link>

  const task =
    <Link className="menu-option" to="/task" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Revisión de tareas" src="ico-tarea-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Tareas</label></section>
    </Link>

  const catalog =
    <Link className="menu-option" to="/catalog" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Configuración del catálogo" src="ico-catalogo-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text" >Conf catálogo</label></section>
    </Link>

  const process =
    <Link className="menu-option" to="/process" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Procesos" src="ico-proceso-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Procesos</label></section>
    </Link>

  const levelAdvance =
    <Link className="menu-option" to="/levelAdvance" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Avance de nivel" src="ico-proceso-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Avance</label></section>
    </Link>

  const calendar =
    <Link className="menu-option" to="/calendar" onClick={toogleMenu}>
      <section className="menu-option-image"><img alt="Calendario" src="ico-calendario-blanco.png"></img></section>
      <section className="menu-option-text"><label className="light-text">Calendario</label></section>
    </Link>

  return (
    <section className="menu">

      <section className="menu-options">

        {roles.includes('Admin') || roles.includes('Super usuario') ? usersMenu : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? questionnairiesMenu : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? textForPlanMenu : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? diagnosticsForActivateConsulting : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? task : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? catalog : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? process : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? levelAdvance : ''}
        {roles.includes('Admin') || roles.includes('Super usuario') ? calendar : ''}

        {roles.includes('Alumno') ?
          <>
            <Link className="menu-option" to="/catalogs" onClick={toogleMenu}>
              <section className="menu-option-image"><img alt="Mostrar catálogos" src="ico-catalogo-blanco.png"></img></section>
              <section className="menu-option-text"><label className="light-text" >Catálogos</label></section>
            </Link>

            <Link className="menu-option" to="/diagnostic" onClick={toogleMenu}>
              <section className="menu-option-image"><img alt="Diagnóstico" src="ico-diagnostico-blanco.png"></img></section>
              <section className="menu-option-text"><label className="light-text">Diagnóstico</label></section>
            </Link>

            <Link className="menu-option" to="/calendarForUser" onClick={toogleMenu}>
              <section className="menu-option-image"><img alt="Mi calendario" src="ico-calendario-blanco.png"></img></section>
              <section className="menu-option-text"><label className="light-text">Mi calendario</label></section>
            </Link>

            <Link className="menu-option" to="/taskOfStudent" onClick={toogleMenu}>
              <section className="menu-option-image"><img alt="Mis tareas" src="ico-tarea-blanco.png"></img></section>
              <section className="menu-option-text"><label className="light-text">Mis tareas</label></section>
            </Link>

            <Link className="menu-option" to="/score" onClick={toogleMenu}>
              <section className="menu-option-image"><img alt="Score" src="ico-puntaje-blanco.png"></img></section>
              <section className="menu-option-text"><label className="light-text">Score</label></section>
            </Link>
          </>
          : ''}

        <Link className="menu-option" to="#" onClick={e => setGoExit(true)}>
          <section className="menu-option-image"><img alt="Salir" src="ico-cerrar-blanco.png"></img></section>
          <section className="menu-option-text"><label className="light-text">Salir</label></section>
        </Link>

      </section>

      {goExit ? <Redirect from="#" to="/exit" /> : ''}

    </section>
  )
}

export default Menu
