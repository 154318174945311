// CalendarForUser ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import '../style/general.css'
import '../style/elements.css'

function CalendarForUser() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [activities, setActivities] = useState([])

  // local methods   
  const listActivities = useCallback(() => {
    // call api to list activities
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Calendar/listCalendarForUser?userId=${userInfo.userId}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setActivities(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])

  // local components  
  let rowIdx = 0
  const listItems = activities.map((activityRow) => {
    const cardColor = (rowIdx % 2 === 0 ? 'even' : 'odd')
    const blockClass = `item-card card-color-${cardColor}`
    const blockDescriptionClass = `item-card-description card-description-color-${cardColor}`
    rowIdx++
    return (
      <section className={blockClass} key={activityRow.id}>
        <section className="item-card-id">Actividad #{activityRow.id}</section>
        <section className="item-card-id">Programada:</section>
        <section className="item-card-questionnaire">{activityRow.updated}</section>
        <section className="item-card-questionnaire">{activityRow.descriptionOfTheCommitment}</section>
        <section className="item-card-id"> <span role="img" aria-label="simbolo"> &#128199; </span> </section>
        <section className={blockDescriptionClass}>{activityRow.dateTime}</section>

      </section>)
  })

  const list =
    <>
      <section className="module">
        <img alt="Proceso" src="ico-calendario.png"></img>
        <label className="title-font big-text">Mi calendario</label>
      </section>
      <section className="item-cards">
        {listItems}
      </section>
    </>

  useEffect(() => {
    listActivities()
  }, [listActivities])

  return (
    <>
      {list}
    </>
  )
}

export default CalendarForUser
