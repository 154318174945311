// Landing ...

import React from 'react'
import { useHistory } from 'react-router-dom'

import '../style/general.css'
import '../style/elements.css'
import '../style/login.css'

function Landing() {

  // locals
  const history = useHistory();

  // local methods
  const goToRegister = () => {
    history.push('register');
  }
  const goToLogin = () => {
    history.push('login');
  }

  return (
    <>

      <section className="header-product">
        <img src="foto-landing.jpg" alt="foto" style={{ width: '100%', height: 'auto' }}></img>
      </section>

      <section className="buttons home-links" style={{ marginTop: '5vh', marginBottom: "5vh" }}>
        <button className="link title-font big-text" onClick={goToRegister}>Registrarse</button>
        <button className="link title-font big-text" onClick={goToLogin}>Entrar</button>
      </section>

      <section className="header-product">
        <img src="logo-verde.png" className="logo" alt="logo"></img>
      </section>

      <section className="long-text" style={{ marginTop: '5vh' }}>
        El proposito es brindar soporte al proceso consultivo con empresarios Agrícolas,
        Agricultores, Agrónomos y Unidades Agrícolas en general, que quieran hacer parte
        de una serie de programas en pro de fomentar la Agricultura Sostenible en el País.
      </section>

      <section className="long-text" style={{ marginTop: '5vh', marginBottom: '5vh' }}>
        <label className="super-big-text" style={{ textDecoration: "underline" }}>&nbsp;&nbsp;&nbsp;MÁS DE&nbsp;</label>
        <label className="super-big-text" style={{ textDecoration: "underline", color: "var(--secondary)" }} >10&nbsp;&nbsp;&nbsp;</label>
        <section>Programas de desarrollo agrícola</section>
      </section>

      <section className="boxes">
        <section className="box card">
          <section className="card-image">
            <img src="comic-regando.png" alt="comic"></img>
          </section>
          <section className="card-details">
            <section className="sub-title light-text">
              Técnico
            </section>
            <section className="big-text card-title title-font light-text">
              Pre-siembra
            </section>
            <section className="paragraph light-text">
              Paso a paso del análisis del suelo, la preparación del terreno y el alistamiento de la semilla
            </section>
          </section>
        </section>
        <section className="box card">
          <section className="card-image">
            <img src="comic-regando.png" alt="comic"></img>
          </section>
          <section className="card-details" style={{backgroundColor: "var(--secondary)"}}>
            <section className="sub-title light-text">
              Financiero
            </section>
            <section className="big-text card-title title-font light-text">
              Riego
            </section>
            <section className="paragraph light-text">
              Análisis de costos de montaje y operación del sistema de riego
            </section>
          </section>
        </section>
      </section>

    </>
  )
}

export default Landing
