// DiagnosticsForActivateConsulting ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import '../style/general.css'
import '../style/elements.css'

function DiagnosticsForActivateConsulting() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [diagnostics, setDiagnostics] = useState([])

  // local methods 
  const listDiagnostics = useCallback(() => {
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Diagnostic/ListForActivateConsulting`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setDiagnostics(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])

  const activateConsulting = async (diagnosticId, userEmail) => {

    if (!window.confirm('Confirma la activación de la consultoría?')) return

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Diagnostic/post`
    fetch(URL, { method: 'POST', headers: headers, body: JSON.stringify(diagnosticId) })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        listDiagnostics()
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }

  // local components
  const listItems = diagnostics.map((row) =>
    <tr key={row.id} >
      <td className="od">{row.questionnaire}</td>
      <td>{row.userEmail}</td>
      <td className="od">{row.structure.total}</td>
      <td>
        <button style={{ maxWidth: '100px', minWidth: '100px' }} onClick={e => activateConsulting(row.id, row.userEmail)}>Activar</button>
      </td>
    </tr>
  )

  const list =
    <>
      <section className="module">
        <img alt="Diagnóstico" src="ico-diagnostico.png"></img>
        <label className="title-font big-text">Listado de diagnosticos para activar consultoría</label>
      </section>
      <table>
        <thead>
          <tr>
            <th className="od">Cuestionario</th>
            <th>Usuario</th>
            <th className="od">Score</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
      </table>
    </>

  useEffect(() => {
    listDiagnostics()
  }, [listDiagnostics])

  return (
    <>
      {list}
    </>
  )
}

export default DiagnosticsForActivateConsulting
