// Questions ...

import React, { useState } from "react"

import { useGlobalState } from '../util/State'
import { QuestionClass } from '../model/Classes'

import Answers from './Answers'

import '../style/general.css'
import '../style/elements.css'

function Questions(props) {

  const { questionnaireId, answer, setAnswer, questions, setQuestions } = props

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [question, setQuestion] = useState(new QuestionClass())
  const [showingList, setShowingList] = useState(true)


  // local methods  
  const getUniqueId = () => {
    return `${userInfo.sessionId}.${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}.${new Date().getHours()}.${new Date().getMinutes()}.${new Date().getSeconds()}.${new Date().getMilliseconds()}`;
  }
  const edit = async id => {
    let questionLocal = questions.find(x => x.id === id)
    if (questionLocal == null) {
      questionLocal = new QuestionClass()
      questionLocal.id = getUniqueId()
    }
    setQuestion(questionLocal)
    setShowingList(false)
  }
  const setFieldChange = async (e) => {
    const target = e.target;
    const value = (target.type === 'checkbox' ? target.checked : (target.type === 'number' ? parseInt(target.value) : target.value))
    const name = target.name;
    setQuestion({ ...question, [name]: value });
  }
  const save = async e => {
    e.preventDefault()
    const questionIdx = questions.findIndex(x => x.id === question.id)
    questions.splice(questionIdx, (questionIdx >= 0 ? 1 : 0), question)
    const repeatedOrders = questions.map(m => m.order).filter((e, i, a) => a.indexOf(e) !== i)
    if (repeatedOrders.length > 0) {
      alert('El orden de cada pregunta debe ser único')
      return
    }
    const questionsLocal = questions.filter(x => x.text.trim() !== '' && x.order > 0)
    setQuestions(questionsLocal)
    setShowingList(true)
  }
  const add = async => {
    edit('');
  }

  // local components
  const tableRows = questions.map((row) =>
    <tr key={row.id} onClick={e => edit(row.id)}>
      <td>{row.text}</td>
      <td>{row.order}</td>
    </tr>
  )
  const questionsTable =
    <>
      <section className="module">
        <label className="title-font">Listado de preguntas</label>
      </section>
      <table className="sub-table">
        <thead>
          <tr>
            <th>Pregunta</th>
            <th>Orden</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <button type="button" className="mini-button alternate-button" title="Nueva pregunta" onClick={() => add()}>&#10010;</button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>

  const answerComp =
    <>
      <fieldset>
        <legend>Respuestas</legend>
        <Answers questionId={question.id} answer={answer} setAnswer={setAnswer}></Answers>
      </fieldset>
    </>


  const editForm =
    <>
      <form onSubmit={save} >
        <section className="module">
          <label className="title-font">Edición pregunta</label>
        </section>
        <section className="sub-forms">
          <section className="field">
            <input id="text" name="text"
              value={question.text} onChange={setFieldChange}
              type="text" className="input" required={true} placeholder="Pregunta"></input>
          </section>
          <section className="field">
            <input id="order" name="order"
              value={question.order} onChange={setFieldChange}
              type="number" className="input" required={true} placeholder="Orden de aparición" title="Orden de aparición"></input>
          </section>
        </section>

        <section className="sub-buttons">
          <button type="button" className="mini-button alternate-button" onClick={() => setShowingList(true)} title="Lista">&#10094;</button>
          <button type="submit" className="mini-button" title="Guardar">&#10004;</button>
        </section>
      </form>

      { questionnaireId > 0 && answerComp}
    </>

  return (
    <>
      {showingList ? questionsTable : editForm}
    </>
  )
}

export default Questions
