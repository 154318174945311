// Catalog configuration ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL, RecordStatus } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import { CatalogStructure } from '../model/Classes'

import '../style/general.css'
import '../style/elements.css'
import '../style/card.css'

function Catalog() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [showingList, setShowingList] = useState(true)
  const [questionnairies, setQuestionnairies] = useState([])
  const [catalogs, setCatalogs] = useState([])
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState(-1)
  const [catalog, setCatalog] = useState({ id: -1, questionnaireId: currentQuestionnaireId, description: '', imagePath: '', status: RecordStatus.Activo, structure: new CatalogStructure() })
  const [fileRecord, setFileRecord] = useState({})

  // file handle methods 
  const getFileId = () => {
    return `${userInfo.sessionId}.${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}.${new Date().getHours()}.${new Date().getMinutes()}.${new Date().getSeconds()}.${new Date().getMilliseconds()}`
  }
  const getPreview = async (target) => {
    const localFile = { fileId: '', name: '', image: null, preview: null }
    localFile.fileId = getFileId()
    const file = localFile.image = target.files[0]
    localFile.name = file.name

    if (file.type.startsWith('image')) {
      try {
        const res = await fetch(URL.createObjectURL(file))
        const b = await res.blob()
        localFile.preview = URL.createObjectURL(b)
      } catch (error) {
        alert(`Por favor intente de nuevo. Ocurrio un ${error} [getPreview]`)
      }
    }
    setFileRecord(localFile)
    return localFile
  }
  const saveFormFile = async () => {
    if (fileRecord && fileRecord.fileId) {
      try {
        const URL = `${API_URL}/file/saveFormFile`
        const headers = { 'Authorization': `Bearer ${userInfo.sessionId}` }
        const formData = new FormData()
        formData.append('FileId', fileRecord.fileId)
        formData.append('Image', fileRecord.image)

        const response = await fetch(URL, { method: 'PUT', headers: headers, body: formData })
        const data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)

        console.info('Se cargo el anexo a la tarea.')
        listCatalogs(catalog.questionnaireId)
      } catch (error) { alert(`Por favor intente de nuevo. Ocurrio un ${error} [saveFormFile]`) }
    } else {
      listCatalogs(catalog.questionnaireId)
    }
  }
  const getImage = async (fileId) => {
    setFileRecord({})
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    try {
      const ENDPOINT_URL = `${API_URL}/file/get/?fileId=${fileId}`
      const response = await fetch(ENDPOINT_URL, { method: 'GET', headers: headers })
      const data = await response.blob()
      if (data.message) throw new Error(data.message)
      if (data.status && data.status !== 200) throw new Error(data.title)
      const localFile = { fileId: fileId, name: '', image: null, preview: URL.createObjectURL(data) }
      setFileRecord(localFile)
      return localFile
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }
  const getImages = async (catalogs) => {
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    try {
      for (let idx = 0; idx < catalogs.length; idx++) {
        const catalog = catalogs[idx]
        const fileId = catalog.imagePath
        const ENDPOINT_URL = `${API_URL}/file/get/?fileId=${fileId}`
        const response = await fetch(ENDPOINT_URL, { method: 'GET', headers: headers })
        const data = await response.blob()
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        const img = { fileId: fileId, name: '', image: null, preview: URL.createObjectURL(data) }
        catalog.img = img
      }
      setCatalogs(catalogs)
      setShowingList(true)
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }
  // const download = async (taskRow) => {
    
  //   const fileName = taskRow.documentPath
  //   if (fileName === null || fileName === undefined || fileName === '') return
  //   const fileRecordLocal = await getImage(fileName)
  //   const url = fileRecordLocal.preview
  //   const a = document.createElement('a')
  //   document.body.appendChild(a)
  //   a.href = url
  //   a.download = taskRow.structure.name
  //   a.click()
  //   setTimeout(() => {
  //     window.URL.revokeObjectURL(url)
  //     document.body.removeChild(a)
  //   }, 0)
  // }

  // local methods   
  const listQuestionnairies = useCallback(() => {
    // call api to list questionnairies
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Questionnaire/list`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setQuestionnairies(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])
  const listCatalogs = async (questionnaireId) => {
    setCurrentQuestionnaireId(parseInt(questionnaireId))
    // call api to list catalogs
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const qs = `questionnaireId=${questionnaireId}`
    const URL = `${API_URL}/Catalog/list/?${qs}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        getImages(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const editItem = async id => {
    let catalogLocal = catalogs.find(x => x.id === id)
    if (catalogLocal == null) {
      catalogLocal = { id: -1, questionnaireId: currentQuestionnaireId, description: '', imagePath: '', status: RecordStatus.Activo, structure: new CatalogStructure() }
    }
    setCatalog(catalogLocal)
    getImage(catalogLocal.imagePath)
    setShowingList(false)
  }
  const setFormFieldChange = async (e) => {
    const target = e.target
    const value = (target.type === 'checkbox' ? target.checked : (target.type === 'number' ? parseFloat(target.value) : target.value))
    const name = target.name
    if (target.type === 'file') {
      const file = await getPreview(target)
      const t = { ...catalog }

      const s = { ...t.structure }
      s.isImage = (file.preview != null)
      s.fileId = file.fileId
      s.name = file.name

      t.structure = s
      t.imagePath = file.fileId
      setCatalog(t)
    } else if (name === 'text') {
      const t = { ...catalog }
      t.structure = { ...t.structure, [name]: value }
      setCatalog(t)
    } else setCatalog({ ...catalog, [name]: value })
  }
  const submit = async e => {
    e.preventDefault()

    if (!window.confirm('Confirma los cambios que acaba de realizar?')) return

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Catalog/put`
    fetch(URL, { method: 'PUT', headers: headers, body: JSON.stringify(catalog) })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        if (catalog.id === null || catalog.id === undefined || catalog.id === 0 || catalog.id === -1) saveFormFile()
        else listCatalogs(catalog.questionnaireId)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }
  const addNewCatalog = async => {
    if (currentQuestionnaireId == null || currentQuestionnaireId === -1) {
      alert('No ha elegido un cuestionario')
      return
    }
    editItem(-1)
  }

  // const getStatusName = statusId =>
  //   Object.entries(RecordStatus).find(x => x[1] === statusId)[0]

  // local components
  const questionnaireOptions = questionnairies.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.name}</option>
    )
  })

  let rowIdx = 0
  const listItems = catalogs.map((catalogRow) => {
    const cardColor = (rowIdx % 2 === 0 ? 'even' : 'odd')
    const blockClass = `item-card card-color-${cardColor}`
    const blockButtonClass = `card-button-color-${cardColor}`
    const blockDescriptionClass = `item-card-description card-description-color-${cardColor}`
    rowIdx++
    return (
      <section className="item-card-with-image" key={catalogRow.id}>
        <section className="item-card-image">
          {(catalogRow.img && catalogRow.img.preview) ? (<img src={catalogRow.img.preview} alt="representación del catálogo" />) : ''}
        </section>
        <section className={blockClass}>
          <section className="item-card-id">Catálogo #{catalogRow.id}</section>
          <section className={blockDescriptionClass}>{catalogRow.description}</section>
          <section className="item-card-button">
            <button type="button" className={blockButtonClass} onClick={e => editItem(catalogRow.id)}>Editar</button>
          </section>
        </section>
      </section>)
  })

  const list =
    <>
      <section className="module">
        <img alt="Catálogo" src="ico-catalogo.png"></img>
        <label className="title-font big-text">Configuración del catálogo</label>
      </section>
      <>
        <select id="currentQuestionnaire" name="currentQuestionnaire"
          value={currentQuestionnaireId} onChange={e => { listCatalogs(e.target.value) }}
          className="input" required={true} style={{ width: '100%' }}>
          <option key={-1} value={-1}>Seleccione un cuestionario</option>
          {questionnaireOptions}
        </select>
      </>
      <section className="item-cards">
        {listItems}
      </section>
      <section className="item-cards-buttons">
        <button type="button" className="alternate-button" onClick={() => addNewCatalog()} title="Nuevo">&#10010;</button>
      </section>
    </>

  const statusItems = Object.entries(RecordStatus).map(e => {
    return (
      <option key={e[1]} value={e[1]}>{e[0]}</option>
    )
  })

  const edit =
    <form onSubmit={submit} style={{ display: 'contents' }}>
      <section className="module">
        <img alt="Catálogo" src="ico-catalogo.png"></img>
        <label className="title-font big-text">Edición catálogo</label>
      </section>
      <section className="forms">
        <section className="field">
          <label htmlFor="description" >Descripción</label>
          <input id="description" name="description"
            value={catalog.description} onChange={setFormFieldChange}
            type="text" className="input" required={true}></input>
        </section>

        <section className="field">
          <label htmlFor="imagePath" >Imagen</label>

          {(catalog.imagePath === '') ?
            (<input id="imagePath" name="imagePath" onChange={setFormFieldChange} type="file" accept="image/*" className="input" required={true} alt="Elija un archivo de su dispositivo"></input>) :
            (<h5>Ya cargó la imagen del catálogo</h5>)}

          {(fileRecord.preview) ?
            (<img src={fileRecord.preview} width="200" height="200" alt="imagen cargada de su dispositivo" />) :
            (<h5>Elija un archivo de su dispositivo</h5>)}

        </section>

        <section className="field">
          <label htmlFor="status" >Estado</label>
          <select id="status" name="status"
            value={catalog.status} onChange={setFormFieldChange}
            className="input" required={true}>
            {statusItems}
          </select>
        </section>

      </section>

      <section className="buttons">
        <button type="button" className="alternate-button" onClick={() => setShowingList(true)}>Volver</button>
        <button type="submit" >Guardar</button>
      </section>
    </form>

  useEffect(() => {
    listQuestionnairies()
  }, [listQuestionnairies])

  return (
    <>
      {showingList ? list : edit}
    </>
  )
}

export default Catalog
