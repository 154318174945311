// Score ...

import React, { useState, useEffect, useCallback } from "react"

import { useGlobalState } from '../util/State';
import { API_URL } from '../util/ConstValues'

import '../style/general.css'
import '../style/elements.css'
import '../style/score.css'

function Score() {

  // globales
  const [userInfo,] = useGlobalState('userInfo')

  // locales  
  const [diagnostic, setDiagnostic] = useState({ structure: { total: 0 } })
  const [scorePosition, setScorePosition] = useState('0px')
  const [showScore, setShowScore] = useState(true)
  const [questionnaire, setQuestionnaire] = useState({ name: '' })
  const [textForPlan, setTextForPlan] = useState('')
  const scoreBarWidth = 357

  const getScore = useCallback(() => {
    const URL = `${API_URL}/Diagnostic/list`
    fetch(URL, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        if (data && data.length > 0) {
          const diagnosticLoc = data.sort((a, b) => a.updated - b.updated).find(v => v)
          setDiagnostic(diagnosticLoc)
          const scorePositionLoc = scoreBarWidth * diagnosticLoc.structure.total / 100
          setScorePosition(`${scorePositionLoc}px`)
        }
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo, setDiagnostic])

  const getTextsForPlan = async () => {
    const URL = `${API_URL}/TextForPlan/retrieve/?questionnaireId=${diagnostic.questionnaireId}&score=${diagnostic.structure.total}`
    fetch(URL, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    })
      .then(response => response.text())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        setTextForPlan(data)
        setShowScore(false)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }

  const showDiagnostic = async () => {
    const URL = `${API_URL}/Questionnaire/retrieve/?id=${diagnostic.questionnaireId}`
    fetch(URL, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        setQuestionnaire(data)
        getTextsForPlan()
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }

  const getNumberOfQuestions = () => {
    return questionnaire.numberOfQualitativeQuestions + questionnaire.numberOfQuantitativeQuestions
  }

  const getCorrectAnswers = () => {
    if (diagnostic.structure.results) return diagnostic.structure.results.filter(x => x.isCorrect === true).length
    else return 0
  }

  const getIncorrectAnswers = () => {
    if (diagnostic.structure.results) return diagnostic.structure.results.filter(x => x.isCorrect === false).length
    else return 0
  }

  useEffect(() => {
    getScore()
  }, [getScore])

  const scoreComp =
    <>
      <section className="module">
        <img alt="Score" src="ico-puntaje.png"></img>
        <label className="title-font big-text">Score</label>
      </section>

      <section className="boxes">
        <div>
          <section className="title-font big-text">
            Mi score es:
          </section>
          <section className="ultra-big-text secondary-text">
            {diagnostic.structure.total}
          </section>
          <div className="score-bar-container">
            <img alt="Señalador" className="signaler" style={{ left: scorePosition }} src="ico-flecha.png"></img>
            <div className="score-bar">&nbsp;</div>
            <div className="score-bar-values">
              <div className="score-bar-min"> 10 </div>
              <div className="score-bar-max"> 100 </div>
            </div>
          </div>
        </div>
      </section>

      <section className="buttons">
        <button type="button" onClick={showDiagnostic}>Ver diagnóstico</button>
      </section>
    </>

  const diagnosticComp =
    <>
      <section className="module">
        <img alt="Score" src="ico-puntaje.png"></img>
        <label className="title-font big-text">Score</label>
      </section>

      <section className="boxes">
        <div>
          <section className="box big-box back-2" style={{ width: '95vw' }}>
            <section className="title-font big-text">Cuestionario respondido : </section>
            <section className="big-text">{questionnaire.name}</section>
          </section>

          <table style={{
            minWidth: '80vw'
          }}>
            <tbody>
              <tr key="1">
                <td>Número de preguntas</td>
                <td key="2">{getNumberOfQuestions()}</td>
              </tr>
              <tr>
                <td>Respuestas correctas</td>
                <td key="3">{getCorrectAnswers()}</td>
              </tr>
              <tr>
                <td>Respuestas incorrectas</td>
                <td key="3">{getIncorrectAnswers()}</td>
              </tr>
            </tbody>
          </table >

          <section className="score-plan">
            <section className="title-font big-text" style={{ color: "var(--tertiary)" }} > Plan </section>
            <section style={{ color: "var(--tertiary)" }}> {textForPlan} </section>
          </section>

        </div>
      </section>

      <section className="buttons">
        <button className="alternate-button" type="button" >Ir al plan</button>
      </section>
    </>

  return (
    <>
      {showScore ? scoreComp : diagnosticComp}
    </>
  )
}

export default Score
