/* API endpoint */
const
    // API_URL = "http://localhost:5000/api"
    API_URL = "https://sustenta.co/sscsas-api"
    ;


/* Enum for status support of system */
const RecordStatus = Object.freeze({
    Activo: 'A',
    Suspendido: 'S',
    Executed: 'E'
});

export { API_URL, RecordStatus }