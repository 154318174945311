// TaskOfStudent ...

import React, { useState, useEffect, useCallback } from "react"
import { Link } from 'react-router-dom'

import { API_URL, RecordStatus } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import { TaskStructure } from '../model/Classes'

import '../style/general.css'
import '../style/elements.css'
import '../style/card.css'

function TaskOfStudent() {

  // pendiente en estructura, grabar nombre archivo cargado

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [showingList, setShowingList] = useState(true)
  const [consultingActivators, setConsultingActivators] = useState([])
  const [tasks, setTasks] = useState([])
  const [currentConsultingActivatorId, setCurrentConsultingActivatorId] = useState(-1)
  const [task, setTask] = useState({ id: -1, consultingActivatorId: currentConsultingActivatorId, documentPath: '', status: RecordStatus.Activo, structure: new TaskStructure() })
  const [fileRecord, setFileRecord] = useState({})

  // file handle methods 
  const getFileId = () => {
    return `${userInfo.sessionId}.${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}.${new Date().getHours()}.${new Date().getMinutes()}.${new Date().getSeconds()}.${new Date().getMilliseconds()}`
  }
  const getPreview = async (target) => {
    const localFile = { fileId: '', name: '', image: null, preview: null }
    localFile.fileId = getFileId()
    const file = localFile.image = target.files[0]
    localFile.name = file.name

    if (file.type.startsWith('image')) {
      try {
        const res = await fetch(URL.createObjectURL(file))
        const b = await res.blob()
        localFile.preview = URL.createObjectURL(b)
      } catch (error) {
        alert(`Por favor intente de nuevo. Ocurrio un ${error} [getPreview]`)
      }
    }
    setFileRecord(localFile)
    return localFile
  }
  const saveFormFile = async () => {
    if (fileRecord && fileRecord.fileId) {
      try {
        const URL = `${API_URL}/file/saveFormFile`
        const headers = { 'Authorization': `Bearer ${userInfo.sessionId}` }
        const formData = new FormData()
        formData.append('FileId', fileRecord.fileId)
        formData.append('Image', fileRecord.image)

        const response = await fetch(URL, { method: 'PUT', headers: headers, body: formData })
        const data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)

        console.info('Se cargo el anexo a la tarea.')
        listTasks(task.consultingActivatorId)
      } catch (error) { alert(`Por favor intente de nuevo. Ocurrio un ${error} [saveFormFile]`) }
    } else {
      listTasks(task.consultingActivatorId)
    }
  }
  const getImage = async (fileId) => {
    setFileRecord({})
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    try {
      const ENDPOINT_URL = `${API_URL}/file/get/?fileId=${fileId}`
      const response = await fetch(ENDPOINT_URL, { method: 'GET', headers: headers })
      const data = await response.blob()
      if (data.message) throw new Error(data.message)
      if (data.status && data.status !== 200) throw new Error(data.title)
      const localFile = { fileId: fileId, name: '', image: null, preview: URL.createObjectURL(data) }
      setFileRecord(localFile)
      return localFile
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }
  const download = async (taskRow) => {
    
    const fileName = taskRow.documentPath
    if (fileName === null || fileName === undefined || fileName === '') return
    const fileRecordLocal = await getImage(fileName)
    const url = fileRecordLocal.preview
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = taskRow.structure.name
    a.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }

  // local methods 
  const listConsultingActivators = useCallback(() => {
    // call api to list consultingActivators
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/consultingActivator/listOnlyMe`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setConsultingActivators(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])
  const listTasks = async (consultingActivatorId) => {
    if (consultingActivatorId === -1) return
    setCurrentConsultingActivatorId(parseInt(consultingActivatorId))
    // call api to list tasks
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const qs = `consultingActivatorId=${consultingActivatorId}`
    const URL = `${API_URL}/Task/list/?${qs}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setTasks(data)
        setShowingList(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const editItem = async id => {
    setFileRecord({})
    let taskLocal = tasks.find(x => x.id === id)
    if (taskLocal == null) {
      taskLocal = { id: -1, consultingActivatorId: currentConsultingActivatorId, documentPath: '', status: RecordStatus.Activo, structure: new TaskStructure() }
    }
    setTask(taskLocal)
    getImage(taskLocal.documentPath)
    setShowingList(false)
  }
  const setFormFieldChange = async (e) => {
    const target = e.target
    const value = (target.type === 'checkbox' ? target.checked : (target.type === 'number' ? parseFloat(target.value) : target.value))
    const name = target.name
    
    if (target.type === 'file') {
      const file = await getPreview(target)
      const t = { ...task }
      
      const s = { ...t.structure }
      s.isImage = (file.preview != null)
      s.fileId = file.fileId
      s.name = file.name

      t.structure = s      
      t.documentPath = file.fileId
      setTask(t)
    } else if (name === 'text') {
      const t = { ...task }
      t.structure = { ...t.structure, [name]: value }
      setTask(t)
    } else setTask({ ...task, [name]: value })
  }
  const submit = async e => {
    e.preventDefault()

    if (!window.confirm('Confirma los cambios que acaba de realizar?')) return

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Task/put`
    fetch(URL, { method: 'PUT', headers: headers, body: JSON.stringify(task) })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        if (task.id === null || task.id === undefined || task.id === 0 || task.id === -1) saveFormFile()
        else listTasks(task.consultingActivatorId)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }
  const addNewTask = async => {
    if (currentConsultingActivatorId == null || currentConsultingActivatorId === -1) {
      alert('No ha elegido un activador de consultoría')
      return
    }
    editItem(-1)
  }

  // local components
  const consultingActivatorOptions = consultingActivators.map(e => {
    return (
      <option key={e.id} value={e.id}>{'Codigo: ' + e.code + ' Usuario: ' + e.consultingUser + ' Score: ' + e.diagnosticTotalResult}</option>
    )
  })

  let rowIdx = 0
  const listItems = tasks.map((taskRow) => {
    const cardColor = (rowIdx % 2 === 0 ? 'even' : 'odd')
    const blockClass = `item-card card-color-${cardColor}`
    const blockButtonClass = `card-button-color-${cardColor}`
    const blockDescriptionClass = `item-card-description card-description-color-${cardColor}`
    rowIdx++
    return (
      <section className={blockClass} key={taskRow.id}>
        <section className="item-card-id">Tarea #{taskRow.id}</section>
        <section className={blockDescriptionClass}>{taskRow.structure.text}</section>
        <section className="item-card-link">
          <Link className="label link" to="#" onClick={e => download(taskRow)}>descargar</Link>
        </section>
        <section className="item-card-button">
          <button type="button" className={blockButtonClass} onClick={e => editItem(taskRow.id)}>Editar</button>
        </section>
      </section>)
  })

  const list =
    <>
      <section className="module">
        <img alt="Usuario" src="ico-tarea.png"></img>
        <label className="title-font big-text">Mis tareas</label>
      </section>
      <>
        <select id="currentQuestionnaire" name="currentQuestionnaire"
          value={currentConsultingActivatorId} onChange={e => { listTasks(e.target.value) }}
          className="input" required={true} style={{ width: '100%' }}>
          <option key={-1} value={-1}>Seleccione un activador de consultoría</option>
          {consultingActivatorOptions}
        </select>
      </>
      <section className="item-cards">
        {listItems}
      </section>
      <section className="item-cards-buttons">
        <button type="button" className="alternate-button" onClick={() => addNewTask()} title="Nueva tarea">&#10010;</button>
      </section>
    </>

  const edit =
    <form onSubmit={submit} style={{ display: 'contents' }}>
      <section className="module">
        <img alt="Usuario" src="ico-tarea.png"></img>
        <label className="title-font big-text">Edición Tarea</label>
      </section>
      <section className="forms">
        <section className="field">
          <label htmlFor="documentPath" >Archivo</label>

          {(task.documentPath === '') ?
            (<input id="documentPath" name="documentPath" onChange={setFormFieldChange} type="file" className="input" required={true} alt="Elija un archivo de su dispositivo"></input>) :
            (<h5>Ya cargó el archivo de la tarea</h5>)}

          {(task.structure.isImage && fileRecord.preview) ?
            (<img src={fileRecord.preview} width="200" height="200" alt="imagen cargada de su dispositivo" />) :
            (!task.structure.isImage ? (<Link className="label link" to="#" onClick={e => download(task)} >descargar</Link>)
              : (<h5>Elija un archivo de su dispositivo</h5>))}

        </section>
        <section className="field">
          <label htmlFor="text" >Texto</label>
          <input id="text" name="text"
            value={task.structure.text} onChange={setFormFieldChange}
            type="text" className="input" required={true}></input>
        </section>
      </section>
      <section className="buttons">
        <button type="button" className="alternate-button" onClick={() => setShowingList(true)}>Volver</button>
        <button type="submit" >Guardar</button>
      </section>
    </form>

  useEffect(() => {
    listConsultingActivators()
  }, [listConsultingActivators])

  return (
    <>
      {showingList ? list : edit}
    </>
  )
}

export default TaskOfStudent
