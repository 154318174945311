// Users ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL, RecordStatus } from '../util/ConstValues'
import { useGlobalState } from '../util/State';
import { ActorStructureClass, RelationshipStructureClass } from '../model/Classes'

import '../style/general.css'
import '../style/elements.css'

function Users() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [showingList, setShowingList] = useState(true)
  const [roles, setRoles] = useState([])
  const [rolesForm, setRolesForm] = useState([])
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({
    id: -1, identification: '', name: '',
    profession: '', cargo: '', cellPhoneNumber: '', usr: '', country: '', department: '',
    city: '', address: '', status: RecordStatus.Activo
  })
  const [parameter, setParameter] = useState('');

  // local methods  
  const listRoles = useCallback(() => {
    // call api to list users
    const URL = `${API_URL}/Security/listRoles`
    fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.sessionId}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        console.info('Success:', { data })
        setRoles(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo.sessionId])
  const listUsers = useCallback(() => {
    // call api to list users
    const qs = `parameter=${parameter}&limit=200&offset=0`
    const URL = `${API_URL}/User/listUsers?${qs}`
    fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.sessionId}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        console.info('Success:', { data })
        setUsers(data)
        if (roles.length === 0) listRoles()
        setShowingList(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo.sessionId, roles.length, listRoles, parameter]);
  const listUsersCallback = useCallback(() => {
    listUsers();
  }, [listUsers])

  const editItem = async id => {
    let userLocal = users.find(x => x.id === id)
    if (userLocal == null) {
      userLocal = {
        id: -1, identification: '', name: '',
        profession: '', cargo: '', cellPhoneNumber: '', usr: '', country: '', department: '',
        city: '', address: '', status: RecordStatus.Activo, pwd: '0000'
      }
      // when row no exists
      userLocal.actorStructure = new ActorStructureClass()
      userLocal.relationshipStructure = new RelationshipStructureClass()
      userLocal.roles = [];
    }
    userLocal.profession = userLocal.actorStructure.profession;
    userLocal.country = userLocal.actorStructure.country;
    userLocal.department = userLocal.actorStructure.department;
    userLocal.city = userLocal.actorStructure.city;
    userLocal.address = userLocal.actorStructure.address;
    userLocal.cargo = userLocal.relationshipStructure.cargo

    console.info(userLocal)
    setUser(userLocal)
    setShowingList(false)
    setRolesForm(roles);
  }
  const setUserFieldChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setUser({ ...user, [name]: value });
  }
  const setRoleListChange = async (e) => {
    const target = e.target;
    const role_id = parseInt(target.value);
    const checked = target.checked;

    let rolesLocal = [...user.roles];
    const roleLocal = rolesLocal.find(x => x.roleId === role_id)
    if (roleLocal && !checked) rolesLocal = rolesLocal.filter(x => x !== roleLocal) // remove item unchecked 
    const roleMaster = roles.find(x => x.id === role_id)
    if (roleLocal == null && checked) rolesLocal = [...rolesLocal, { roleId: roleMaster.id, roleName: roleMaster.name, userId: user.id }]; // add item checked 

    const userLocal = { ...user };
    userLocal.roles = rolesLocal;
    setUser(userLocal)
  }
  const submit = async e => {
    e.preventDefault()

    if (!window.confirm('Confirma los cambios que acaba de realizar?')) return
    if (user.roles.length === 0) alert('Al no asignar un rol, el sistema asigna por defecto el de estudiante.')

    user.email = user.usr
    user.actorStructure.profession = user.profession;
    user.actorStructure.country = user.country;
    user.actorStructure.department = user.department;
    user.actorStructure.city = user.city;
    user.actorStructure.address = user.address;
    user.relationshipStructure.cargo = user.cargo;

    const isAdmin = user.roles.filter(x => x.roleId < 3).length > 0
    const endpoint = isAdmin ? '/User/AddAdmin' : '/User/AddStudent';

    const URL = `${API_URL}${endpoint}`
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.sessionId}`,
      },
      body: JSON.stringify(user),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        console.info('Success:', { NewId: data })
        listUsers()
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }
  const addNewUser = async => {
    editItem(-1);
  }
  const getStatusName = statusId =>
    Object.entries(RecordStatus).find(x => x[1] === statusId)[0]

  // local components
  let valueForRowId = 0
  const listItems = users.map((userRow) =>
    <tr key={userRow.id} onClick={e => editItem(userRow.id)}>
      <td> {userRow.name} </td>
      <td>
        {
          userRow.roles.map((role) =>
            <h6 key={`${userRow.id}-${(role.id ? role.id : valueForRowId++)}`} className="gray" >{role.roleName}</h6>
          )
        }
      </td>
      <td className="od"> {getStatusName(userRow.status)} </td>
    </tr>
  )
  const list =
    <>
      <section className="module">
        <img alt="Usuario" src="ico-usuario.png"></img>
        <label className="title-font big-text">Listado de usuarios</label>
      </section>
      <>
        <input value={parameter} onChange={e => { setParameter(e.target.value); listUsers(); }}
          type="text" className="input" placeholder="Filtrar por usuario / email" style={{ textAlign: 'Center' }}></input>
      </>
      <table>
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Roles</th>
            <th className="od">Estado</th>
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <button type="button" className="alternate-button" onClick={() => addNewUser()} title="Nuevo usuario">&#10010;</button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>

  const statusItems = Object.entries(RecordStatus).map(e => {
    if (e[1] !== 'E') {
      return (
        <option key={e[1]} value={e[1]}>{e[0]}</option>
      )
    } else return ''
  });
  const roleItems = rolesForm.map((roleRow) => {

    const isChecked = user.roles.filter(x => x.roleId === roleRow.id).length > 0;

    return (
      <>
        <label key={roleRow.id}>{roleRow.name}</label>
        <input className="input" type="checkbox" name="user_role" value={roleRow.id} checked={isChecked} onChange={setRoleListChange} ></input>
      </>)
  })
  const rolesSection =
    <section className="field">
      <fieldset>
        <legend>Roles</legend>
        {roleItems}
      </fieldset>
    </section>
  const edit =
    <form onSubmit={submit} style={{ display: 'contents' }}>
      <section className="module">
        <img alt="Usuario" src="ico-usuario.png"></img>
        <label className="title-font big-text">Edición usuario</label>
      </section>
      <section className="forms">
        <section className="field">
          <label htmlFor="identification" >No. documento</label>
          <input id="identification" name="identification"
            value={user.identification} onChange={setUserFieldChange}
            type="text" className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="name" >Nombre</label>
          <input id="name" name="name"
            value={user.name} onChange={setUserFieldChange}
            type="text" className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="profession" >Profesión</label>
          <input id="profession" name="profession"
            value={user.profession} onChange={setUserFieldChange}
            type="text" className="input" ></input>
        </section>
        <section className="field">
          <label htmlFor="cargo" >Cargo</label>
          <input id="cargo" name="cargo"
            value={user.cargo} onChange={setUserFieldChange}
            type="text" className="input"  ></input>
        </section>
        <section className="field">
          <label htmlFor="country" >País</label>
          <input id="country" name="country"
            value={user.country} onChange={setUserFieldChange}
            type="text" className="input" ></input>
        </section>
        <section className="field">
          <label htmlFor="department" >Departamento</label>
          <input id="department" name="department"
            value={user.department} onChange={setUserFieldChange}
            type="text" className="input" ></input>
        </section>
        <section className="field">
          <label htmlFor="city" >Municipio</label>
          <input id="city" name="city"
            value={user.city} onChange={setUserFieldChange}
            type="text" className="input" ></input>
        </section>
        <section className="field">
          <label htmlFor="address" >Dirección</label>
          <input id="address" name="address"
            value={user.address} onChange={setUserFieldChange}
            type="text" className="input" ></input>
        </section>
        <section className="field">
          <label htmlFor="cellPhoneNumber" >Teléfono</label>
          <input id="cellPhoneNumber" name="cellPhoneNumber"
            value={user.cellPhoneNumber} onChange={setUserFieldChange}
            type="text" className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="usr" >E-mail</label>
          <input id="usr" name="usr"
            value={user.usr} onChange={setUserFieldChange}
            type="text" className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="status" >Estado</label>
          <select id="status" name="status"
            value={user.status} onChange={setUserFieldChange}
            className="input" required={true}>
            {statusItems}
          </select>
        </section>

        {rolesSection}

      </section>

      <section className="buttons">
        <button type="button" className="alternate-button" onClick={() => setShowingList(true)}>Volver</button>
        <button type="submit" >Guardar</button>
      </section>
    </form>

  useEffect(() => {
    listUsersCallback()
  }, [listUsersCallback])

  return (
    <>
      {showingList ? list : edit}
    </>
  )
}

export default Users
