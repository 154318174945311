class ActorStructureClass {
    constructor() {
        this.profession = ''
        this.country = ''
        this.department = ''
        this.city = ''
        this.address = ''
    }
}

class RelationshipStructureClass {
    constructor() {
        this.cargo = ''
    }
}

class QuestionClass {
    constructor() {
        this.id = ''
        this.text = ''
        this.order = 0
    }
}

class QuestionnaireStructureClass {
    constructor() {
        this.isForDiagnostic = false
        this.qualitativeQuestions = []
        this.quantitativeQuestions = []
    }
}

class TaskStructure {
    constructor() {
        this.Text = ''
    }
}

class CatalogStructure {
    constructor() {
        this.Complement = ''
    }
}

class ProcessStructure {
    constructor() {
        this.description = ''
        this.order = 0
    }
}

export { ActorStructureClass, RelationshipStructureClass, QuestionClass, QuestionnaireStructureClass, TaskStructure, CatalogStructure, ProcessStructure }