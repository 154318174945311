// Diagnostic ...

import React, { useState, useEffect, useCallback } from "react"
import { useHistory } from 'react-router-dom'

import { API_URL } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import '../style/general.css'
import '../style/elements.css'

function Diagnostic() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const history = useHistory();
  const [showingList, setShowingList] = useState(true)
  const [questionnairies, setQuestionnairies] = useState([])
  const [questionnaire, setQuestionnaire] = useState({})
  const [answer, setAnswer] = useState({})
  const [currenQuestion, setCurrentQuestion] = useState({})
  const [currenQuestionAnswers, setCurrenQuestionAnswers] = useState([])
  const [answersForQuestion, setAnswersForQuestion] = useState([])

  // local methods  
  const listQuestionnairies = useCallback(() => {
    // call api to list questionnairies
    const URL = `${API_URL}/Questionnaire/listForDiagnostic`
    fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.sessionId}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        console.info('Success:', { data })
        setQuestionnairies(data)
        setShowingList(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo.sessionId])
  const listQuestionnairiesCallback = useCallback(() => {
    listQuestionnairies();
  }, [listQuestionnairies])
  const retrieve = async (questionnaireId) => {
    try {
      const GET_WEB_METHOD = 'GET'
      const HEADERS = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
      {
        const URL = `${API_URL}/Questionnaire/retrieve/?id=${questionnaireId}`
        const response = await fetch(URL, { method: GET_WEB_METHOD, headers: HEADERS })
        let data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        setQuestionnaire(data)
      }
      {
        const URL = `${API_URL}/Questionnaire/retrieveAnswer/?questionnaireId=${questionnaireId}`
        const response = await fetch(URL, { method: GET_WEB_METHOD, headers: HEADERS })
        const data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        setAnswer(data)
        setShowingList(false)
      }
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }
  const startDiagnostic = async id => {
    console.info(' questionnaireId', id)
    retrieve(id)
  }
  const showScore = async () => {
    history.push('score');
  }
  const consolidateAnswers = async () => {
    const red = answersForQuestion
      .filter(x => x.isCorrect === true)
      .map(x => x.value)
      .reduce((prev, curr) => prev + curr, 0)

    const diagnosticStructure = { results: [...answersForQuestion], total: red }
    const diagnostic = { questionnaireId: questionnaire.id, answerId: answer.id, structure: diagnosticStructure }

    try {
      const PUT_WEB_METHOD = 'PUT'
      const HEADERS = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
      const URL = `${API_URL}/Diagnostic/put`
      fetch(URL, { method: PUT_WEB_METHOD, headers: HEADERS, body: JSON.stringify(diagnostic) })
        .then(response => response.json())
        .then(data => {
          if (data.message) throw new Error(data.message)
          if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
          if (data <= 0) throw new Error('No se guardo el diagnóstico, por favor intende de nuevo')
          showScore()
        })
        .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }

  }
  const showNextQuestion = async () => {
    const allQuestions = [...questionnaire.structure.qualitativeQuestions, ...questionnaire.structure.quantitativeQuestions]
      .sort((a, b) => a.order - b.order)

    for (let index = 0; index < allQuestions.length; index++) {
      const element = allQuestions[index]
      element.index = index
    }

    let currenQuestionLoc = null;
    // question    
    if (currenQuestion.id == null) currenQuestionLoc = allQuestions.find(v => v)
    else currenQuestionLoc = allQuestions
      .filter(x => x.index > currenQuestion.index)
      .sort((a, b) => a.order - b.order)
      .find(v => v)
    // exists
    if (currenQuestionLoc == null) { alert('Termino el cuestionario'); consolidateAnswers(); return; }
    setCurrentQuestion(currenQuestionLoc)
    // answers
    let definition = answer.structure.find(x => x.questionId === currenQuestionLoc.id)
    if (definition == null) { alert('Este cuestionario no esta completamente configurado'); return }
    setCurrenQuestionAnswers(definition.answers)
  }
  const setUserAnswerForQuestion = async (e) => {
    const target = e.target
    const name = target.name
    if (name !== 'questionAnswer') return
    const answerId = target.value
    // answer for question
    let answersForQuestionLoc = [...answersForQuestion]
    let found = answersForQuestionLoc.find(x => x.questionId === currenQuestion.id)
    let afq = currenQuestionAnswers.find(x => x.id === answerId)
    if (found) { found.answerId = answerId; found.value = afq.value; found.isCorrect = afq.isCorrect; }
    else answersForQuestionLoc = [...answersForQuestionLoc, { questionId: currenQuestion.id, answerId: answerId, value: afq.value, isCorrect: afq.isCorrect }]
    setAnswersForQuestion(answersForQuestionLoc)
    console.info(answersForQuestionLoc)
  }

  // local components
  const tableRows = questionnairies.map((row) =>
    <tr key={row.id} onClick={e => startDiagnostic(row.id)}>
      <td>{row.name}</td>
    </tr>
  )
  const table =
    <>
      <section className="module">
        <label className="title-font big-text">Cuestionarios disponibles</label>
      </section>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
        <tfoot>
          <tr>
            <td>
              Seleccione uno para empezar
          </td>
          </tr>
        </tfoot>
      </table>
    </>

  const currenQuestionAnswersComp = currenQuestionAnswers.map((questionAnswer) =>
    <tr key={questionAnswer.id}>
      <td>{questionAnswer.text}</td>
      <td>
        <input type="radio" name="questionAnswer" value={questionAnswer.id} onChange={setUserAnswerForQuestion}></input>
      </td>
    </tr>
  )
  const quesionComp =
    <table>
      <caption>{currenQuestion.text} ?</caption>
      <tbody>
        {currenQuestionAnswersComp}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <button type="button" onClick={showNextQuestion}>{currenQuestion.text ? 'Siguiente' : 'Empezar'}</button>
          </td>
        </tr>
      </tfoot>
    </table>

  const questionnairieComp =
    <section className="multi-part">
      <section className="big-part">
        <fieldset>
          <legend>{questionnaire.name}</legend>
          {quesionComp}
        </fieldset>
      </section>
    </section>

  useEffect(() => {
    listQuestionnairiesCallback()
  }, [listQuestionnairiesCallback])

  return (
    <>
      <section className="module">
        <img alt="Diagnóstico" src="ico-diagnostico.png"></img>
        <label className="title-font big-text">Diagnóstico</label>
      </section>
      {showingList ? table : questionnairieComp}
    </>
  )
}

export default Diagnostic
