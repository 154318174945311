// Questionnairies ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL, RecordStatus } from '../util/ConstValues'
import { useGlobalState } from '../util/State'
import { QuestionnaireStructureClass } from '../model/Classes'

import Questions from './Questions'

import '../style/general.css'
import '../style/elements.css'

function Questionnairies() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [showingList, setShowingList] = useState(true)
  const [questionnairies, setQuestionnairies] = useState([])
  const [questionnaire, setQuestionnaire] = useState({
    id: -1, name: '', numberOfQualitativeQuestions: -1, numberOfQuantitativeQuestions: -1,
    status: '', structure: new QuestionnaireStructureClass()
  })
  const [answer, setAnswer] = useState({
    id: -1, structure: { Definition: [] }
  })

  // local methods  
  const listQuestionnairies = useCallback(() => {
    // call api to list questionnairies
    const URL = `${API_URL}/Questionnaire/list`
    fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.sessionId}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        console.info('Success:', { data })
        setQuestionnairies(data)
        setShowingList(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo.sessionId])
  const listQuestionnairiesCallback = useCallback(() => {
    listQuestionnairies();
  }, [listQuestionnairies])
  const retrieve = async (questionnaireLocal) => {
    try {
      const GET_WEB_METHOD = 'GET'
      const HEADERS = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
      {
        const URL = `${API_URL}/Questionnaire/retrieve/?id=${questionnaireLocal.id}`
        const response = await fetch(URL, { method: GET_WEB_METHOD, headers: HEADERS })
        let data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        setQuestionnaire(data)
        setShowingList(false)
      }
      {
        const URL = `${API_URL}/Questionnaire/retrieveAnswer/?questionnaireId=${questionnaireLocal.id}`
        const response = await fetch(URL, { method: GET_WEB_METHOD, headers: HEADERS })
        const data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        setAnswer(data)
      }
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }
  const edit = async id => {
    let questionnaireLocal = questionnairies.find(x => x.id === id)
    if (questionnaireLocal == null) {

      questionnaireLocal = {
        id: -1, name: '', numberOfQualitativeQuestions: -1, numberOfQuantitativeQuestions: -1,
        status: '', structure: new QuestionnaireStructureClass()
      }
      setQuestionnaire(questionnaireLocal)
      setShowingList(false)

    } else retrieve(questionnaireLocal)
  }
  const setFieldChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setQuestionnaire({ ...questionnaire, [name]: value });
  }
  const submit = async e => {
    e.preventDefault()

    if (!window.confirm('Confirma los cambios que acaba de realizar?')) return

    try {
      const PUT_WEB_METHOD = 'PUT'
      const HEADERS = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
      let questionnaireId = -1;
      {
        const URL = `${API_URL}/Questionnaire/put`
        const response = await fetch(URL, { method: PUT_WEB_METHOD, headers: HEADERS, body: JSON.stringify(questionnaire) })
        const data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        questionnaireId = data
        listQuestionnairies()
      }

      if (questionnaireId > 0) {
        answer.status = questionnaire.status
        const URL = `${API_URL}/Questionnaire/putAnswer`
        const response = await fetch(URL, { method: PUT_WEB_METHOD, headers: HEADERS, body: JSON.stringify(answer) })
        const data = await response.json()
        if (data.message) throw new Error(data.message)
        if (data.status && typeof (data.status) === "number" && data.status !== 200) throw new Error(data.title)
        console.info('NewAnswerId:', data)
      }
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }
  const add = async => {
    edit(-1);
  }
  const setStructureValues = async (e) => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const qs = { ...questionnaire }
    qs.structure = { ...qs.structure, [name]: value }
    setQuestionnaire(qs)
  }
  const setQualitativeQuestions = async questions => {
    const ql = { ...questionnaire };
    ql.structure.qualitativeQuestions = questions
    ql.numberOfQualitativeQuestions = questions.length
    setQuestionnaire(ql);
  }
  const setQuantitativeQuestions = async questions => {
    const ql = { ...questionnaire };
    ql.structure.quantitativeQuestions = questions
    ql.numberOfQuantitativeQuestions = questions.length
    setQuestionnaire(ql);
  }
  const getStatusName = statusId =>
    Object.entries(RecordStatus).find(x => x[1] === statusId)[0]

  // local components
  const tableRows = questionnairies.map((row) =>
    <tr key={row.id} onClick={e => edit(row.id)}>
      <td>{row.name}</td>
      <td className="od">{getStatusName(row.status)}</td>
    </tr>
  )
  const table =
    <>
      <section className="module">
        <img alt="Cuestionarios" src="ico-cuestionario.png"></img>
        <label className="title-font big-text">Listado de cuestionarios</label>
      </section>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th className="od">Estado</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <button type="button" className="alternate-button" onClick={() => add()} title="Nuevo cuestionario">&#10010;</button>
            </td>
          </tr>
        </tfoot>
      </table >
    </>

  const statusItems = Object.entries(RecordStatus).map(e => {
    return (
      <option key={e[1]} value={e[1]}>{e[0]}</option>
    )
  });
  const qualitativeQuestionsComp =
    <fieldset style={{ borderColor: "var(--primary)" }}>
      <legend>Preguntas cualitativas</legend>
      <Questions questionnaireId={questionnaire.id} answer={answer} setAnswer={setAnswer} questions={questionnaire.structure.qualitativeQuestions.sort((a, b) => a.order - b.order)} setQuestions={setQualitativeQuestions}></Questions>
    </fieldset>
  const quantitativeQuestionsComp =
    <fieldset style={{ borderColor: "var(--primary)" }}>
      <legend>Preguntas cuantitativas</legend>
      <Questions questionnaireId={questionnaire.id} answer={answer} setAnswer={setAnswer} questions={questionnaire.structure.quantitativeQuestions.sort((a, b) => a.order - b.order)} setQuestions={setQuantitativeQuestions}></Questions>
    </fieldset>

  const editForm =
    <>
      <form onSubmit={submit} className="part">
        <section className="module">
          <img alt="Cuestionarios" src="ico-cuestionario.png"></img>
          <label className="title-font big-text">Edición de cuestionario</label>
        </section>
        <section className="forms">
          <section className="field">
            <label htmlFor="name" >Nombre</label>
            <input id="name" name="name"
              value={questionnaire.name} onChange={setFieldChange}
              type="text" className="input" required={true}></input>
          </section>
          <hr></hr>
          <section className="field">
            <label >No. de preguntas cualitativas: </label>
            <label className="input">{questionnaire.numberOfQualitativeQuestions}</label>
          </section>
          <section className="field">
            <label >No. de preguntas cuantitativas:</label>
            <label className="input">{questionnaire.numberOfQuantitativeQuestions}</label>
          </section>
          <section className="field">
            <label htmlFor="isForDiagnostic" >Activa diagnóstico</label>
            <input id="isForDiagnostic" name="isForDiagnostic"
              checked={questionnaire.structure.isForDiagnostic} onChange={setStructureValues}
              type="checkbox" className="input"></input>
          </section>
          <section className="field">
            <label htmlFor="status" >Estado</label>
            <select id="status" name="status"
              value={questionnaire.status} onChange={setFieldChange}
              className="input" required={true}>
              {statusItems}
            </select>
          </section>
        </section>

        <section className="buttons">
          <button type="button" className="alternate-button" onClick={() => setShowingList(true)}>Volver</button>
          <button type="submit" >Guardar</button>
        </section>
      </form>

      <section className="big-part">
        {qualitativeQuestionsComp}
        {quantitativeQuestionsComp}
      </section>

    </>

  useEffect(() => {
    listQuestionnairiesCallback()
  }, [listQuestionnairiesCallback])

  return (
    <>
      {showingList ? table : editForm}
    </>
  )
}

export default Questionnairies
