// Task ...

import React, { useState, useEffect, useCallback } from "react"
import { Link } from 'react-router-dom'

import { API_URL } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import '../style/general.css'
import '../style/elements.css'
import '../style/card.css'

function Task() {

  // pendiente en estructura, grabar nombre archivo cargado

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals
  const [, setShowingList] = useState(true)
  const [consultingActivators, setConsultingActivators] = useState([])
  const [tasks, setTasks] = useState([])
  const [currentConsultingActivatorId, setCurrentConsultingActivatorId] = useState(-1)  

  // file handle methods  
  const getImage = async (fileId) => {

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    try {
      const ENDPOINT_URL = `${API_URL}/file/get/?fileId=${fileId}`
      const response = await fetch(ENDPOINT_URL, { method: 'GET', headers: headers })
      const data = await response.blob()
      if (data.message) throw new Error(data.message)
      if (data.status && data.status !== 200) throw new Error(data.title)
      const localFile = { fileId: fileId, name: '', image: null, preview: URL.createObjectURL(data) }

      return localFile
    } catch (error) {
      alert(`Por favor intente de nuevo. Ocurrio un ${error}`)
    }
  }
  const download = async (taskRow) => {
    
    const fileName = taskRow.documentPath
    if (fileName === null || fileName === undefined || fileName === '') return
    const fileRecordLocal = await getImage(fileName)
    const url = fileRecordLocal.preview
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = taskRow.structure.name
    a.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }

  // local methods 
  const listConsultingActivators = useCallback(() => {
    // call api to list consultingActivators
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/consultingActivator/list`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setConsultingActivators(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])
  const listTasks = async (consultingActivatorId) => {
    if (consultingActivatorId === -1) return
    setCurrentConsultingActivatorId(parseInt(consultingActivatorId))
    // call api to list tasks
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const qs = `consultingActivatorId=${consultingActivatorId}`
    const URL = `${API_URL}/Task/list/?${qs}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setTasks(data)
        setShowingList(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }

  // local components
  const consultingActivatorOptions = consultingActivators.map(e => {
    return (
      <option key={e.id} value={e.id}>{'Codigo: ' + e.code + ' Usuario: ' + e.consultingUser + ' Score: ' + e.diagnosticTotalResult}</option>
    )
  })

  let rowIdx = 0
  const listItems = tasks.map((taskRow) => {
    const cardColor = (rowIdx % 2 === 0 ? 'even' : 'odd')
    const blockClass = `item-card card-color-${cardColor}`
    const blockDescriptionClass = `item-card-description card-description-color-${cardColor}`
    rowIdx++
    return (
      <section className={blockClass} key={taskRow.id}>
        <section className="item-card-id">Tarea #{taskRow.id}</section>
        <section className={blockDescriptionClass}>{taskRow.structure.text}</section>
        <section className="item-card-link">
          <Link className="label link" to="#" onClick={e => download(taskRow)}>descargar</Link>
        </section>
      </section>)
  })

  const list =
    <>
      <section className="module">
        <img alt="Usuario" src="ico-tarea.png"></img>
        <label className="title-font big-text">Tareas para la consultoría activada</label>
      </section>
      <>
        <select id="currentQuestionnaire" name="currentQuestionnaire"
          value={currentConsultingActivatorId} onChange={e => { listTasks(e.target.value) }}
          className="input" required={true} style={{ width: '100%' }}>
          <option key={-1} value={-1}>Seleccione un activador de consultoría</option>
          {consultingActivatorOptions}
        </select>
      </>
      <section className="item-cards">
        {listItems}
      </section>
    </>

  useEffect(() => {
    listConsultingActivators()
  }, [listConsultingActivators])

  return (
    <>
      {list}
    </>
  )
}

export default Task
