// Process ...

import React, { useState, useEffect, useCallback } from "react"

import { API_URL, RecordStatus } from '../util/ConstValues'
import { useGlobalState } from '../util/State'

import { ProcessStructure } from '../model/Classes'

import '../style/general.css'
import '../style/elements.css'

function Process() {

  // globals
  const [userInfo,] = useGlobalState('userInfo')

  // locals  
  const [questionnairies, setQuestionnairies] = useState([])
  const [catalogs, setCatalogs] = useState([])
  const [processes, setProcesses] = useState([])
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState(-1)
  const [currentCatalogId, setCurrentCatalogId] = useState(-1)
  const [process, setProcess] = useState({ id: -1, catalogId: currentCatalogId, name: '', status: RecordStatus.Activo, structure: new ProcessStructure() })
  const [showingList, setShowingList] = useState(true)

  const [showingStepList, setShowingStepList] = useState(false)
  const [steps, setSteps] = useState([])
  const [step, setStep] = useState(new ProcessStructure())

  const [stepIdx, setStepIdx] = useState(-1)

  // local methods
  const listQuestionnairies = useCallback(() => {
    // call api to list questionnairies
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Questionnaire/list`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setQuestionnairies(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo])
  const listCatalogs = async (questionnaireId) => {
    setCurrentQuestionnaireId(parseInt(questionnaireId))
    // call api to list processes
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const qs = `questionnaireId=${questionnaireId}`
    const URL = `${API_URL}/Catalog/list/?${qs}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setCatalogs(data)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const listProcess = async (catalogId) => {
    setCurrentCatalogId(parseInt(catalogId))
    // call api to list processes
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Process/list/?catalogId=${catalogId}`
    fetch(URL, { method: 'GET', headers: headers })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        setProcesses(data)
        setShowingList(true)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
    console.log(URL)
  }
  const editItem = async id => {
    let processLocal = processes.find(x => x.id === id)
    if (processLocal == null) {
      processLocal = { id: -1, catalogId: currentCatalogId, name: '', status: RecordStatus.Activo, structure: new ProcessStructure() }
    }
    setProcess(processLocal)
    setShowingList(false)

    processLocal.structure.forEach(x => x.order = parseInt(x.order))
    const sortedProcessSteps = processLocal.structure.sort((a, b) => a.order - b.order)
    setSteps(sortedProcessSteps)

    setShowingStepList(true)
  }
  const setFormFieldChange = async (e) => {
    const target = e.target
    const value = (target.type === 'checkbox' ? target.checked : (target.type === 'number' ? parseInt(target.value) : target.value))
    const name = target.name
    setProcess({ ...process, [name]: value })
  }
  const submit = async e => {
    e.preventDefault()

    if (!window.confirm('Confirma los cambios que acaba de realizar?')) return

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userInfo.sessionId}` }
    const URL = `${API_URL}/Process/put`
    fetch(URL, { method: 'PUT', headers: headers, body: JSON.stringify(process) })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        listProcess(process.catalogId)
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }
  const addNewProcess = async => {
    if (currentCatalogId == null || currentCatalogId === -1) {
      alert('No ha elegido un catálogo')
      return
    }
    editItem(-1)
  }

  // step methods
  const editStep = async order => {
    let stepLocal = steps.find(x => x.order === order)
    if (stepLocal == null) {
      stepLocal = new ProcessStructure()
    }
    setStep(stepLocal)

    setStepIdx(steps.indexOf(stepLocal))

    setShowingStepList(false)
  }
  const setFormStepFieldChange = async (e) => {
    const target = e.target
    const value = (target.type === 'checkbox' ? target.checked : (target.type === 'number' ? parseInt(target.value) : target.value))
    const name = target.name
    setStep({ ...step, [name]: value })
  }
  const submitStep = async () => {
    // const stepIdx = steps.findIndex(x => x.order === step.order)
    steps.splice(stepIdx, (stepIdx >= 0 ? 1 : 0), step)
    const repeatedOrders = steps.map(m => m.order).filter((e, i, a) => a.indexOf(e) !== i)
    if (repeatedOrders.length > 0) {
      alert('El orden de cada paso debe ser único')
      return
    }
    const stepsLocal = steps.filter(x => x.description.trim() !== '' && x.order > 0)

    stepsLocal.forEach(x => x.order = parseInt(x.order))
    const sortedSteps = stepsLocal.sort((a, b) => a.order - b.order)

    setSteps(sortedSteps)
    setShowingStepList(true)

    // update process
    const p = { ...process };
    p.structure = sortedSteps
    setProcess(p);
  }
  const addStep = async => {
    if (process == null) {
      alert('No ha elegido un proceso')
      return
    }
    editStep(-1)
  }

  // step components
  const steplistItems = steps.map((row) => /* steps[] */
    <tr key={row.order} onClick={e => editStep(row.order)}>
      <td>{row.order}</td>
      <td>{row.description}</td>
    </tr>
  )

  const stepTable =
    <table className="sub-table">
      <thead>
        <tr>
          <th>Orden</th>
          <th>Descripción</th>
        </tr>
      </thead>
      <tbody>
        {steplistItems}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <button type="button" className="mini-button alternate-button" title="Nuevo paso" onClick={() => addStep()}>&#10010;</button>
          </td>
        </tr>
      </tfoot>
    </table >

  const stepForm =
    <section style={{ display: 'contents' }}>
      <section className="title title-font big-text">
        Edición del paso
      </section>
      <section className="sub-forms">
        <section className="field">
          <label htmlFor="description" >Descripción</label>
          <input id="description" name="description"
            value={step.description} onChange={setFormStepFieldChange}
            type="text" className="input" required={true}></input>
        </section>
        <section className="field">
          <label htmlFor="order" >Orden</label>
          <input id="order" name="order"
            value={step.order} onChange={setFormStepFieldChange}
            type="number" className="input" required={true}></input>
        </section>
      </section>
      <section className="sub-buttons">
        <button type="button" className="mini-button alternate-button" onClick={() => setShowingStepList(true)} title="Lista">&#10094;</button>
        <button type="submit" className="mini-button" title="Guardar" onClick={submitStep}>&#10004;</button>
      </section>
    </section>

  // local components
  const questionnaireOptions = questionnairies.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.name}</option>
    )
  })

  const catalogOptions = catalogs.map(e => {
    return (
      <option key={e.id} value={e.id}>{e.description}</option>
    )
  })

  const listItems = processes.map((row) =>
    <tr key={row.id} onClick={e => editItem(row.id)}>
      <td>{row.id}</td>
      <td>{row.name}</td>
    </tr>
  )

  const list =
    <>
      <section className="module">
        <img alt="Proceso" src="ico-proceso.png"></img>
        <label className="title-font big-text">Listado de procesos</label>
      </section>
      <>
        <select id="currentQuestionnaire" name="currentQuestionnaire"
          value={currentQuestionnaireId} onChange={e => { listCatalogs(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un cuestionario</option>
          {questionnaireOptions}
        </select>

        <select id="currentCatalog" name="currentCatalog"
          value={currentCatalogId} onChange={e => { listProcess(e.target.value) }}
          className="input" required={true} >
          <option key={-1} value={-1}>Seleccione un catálogo</option>
          {catalogOptions}
        </select>
      </>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <button type="button" className="alternate-button" onClick={() => addNewProcess()} title="Nuevo proceso">&#10010;</button>
            </td>
          </tr>
        </tfoot>
      </table >
    </>

  const edit =
    <form onSubmit={submit} style={{ display: 'contents' }}>
      <section className="module">
        <img alt="Proceso" src="ico-proceso.png"></img>
        <label className="title-font big-text">Edición proceso</label>
      </section>
      <section className="forms">
        <section className="field">
          <label htmlFor="name" >Nombre</label>
          <input id="name" name="name"
            value={process.name} onChange={setFormFieldChange}
            type="text" className="input" required={true}></input>
        </section>
      </section>

      <section className="multi-part">
        <section className="big-part">
          <fieldset>
            <legend>Pasos</legend>
            {showingStepList ? stepTable : stepForm}
          </fieldset>
        </section>
      </section>

      <section className="buttons">
        <button type="button" className="alternate-button" onClick={() => setShowingList(true)}>Volver</button>
        <button type="submit" >Guardar</button>
      </section>
    </form>

  useEffect(() => {
    listQuestionnairies()
  }, [listQuestionnairies])

  return (
    <>
      {showingList ? list : edit}
    </>
  )
}

export default Process
