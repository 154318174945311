// Exit ...

import React, { useState, useEffect, useCallback } from "react"
import { Redirect } from 'react-router-dom'

import { useGlobalState } from '../util/State';
import { API_URL } from '../util/ConstValues'

function Exit(props) {

  const { toogleMenu } = props;

  // globales
  const [userInfo, setUserInfo] = useGlobalState('userInfo')
  const [, setShowMenuButton] = useGlobalState('showMenuButton')

  // locales  
  const [closedSession, setClosedSession] = useState(false)

  const executeExit = useCallback(() => {
    if (userInfo.sessionId == null) {
      toogleMenu();
      return
    }
    const URL = `${API_URL}/Security/exit`
    fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.sessionId}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) throw new Error(data.message)
        if (data.status && data.status !== 200) throw new Error(data.title)
        console.info('Se cerro la sesión:', { data })
        if (data) {
          setClosedSession(data)
          setUserInfo({})
          toogleMenu();
          setShowMenuButton(false)
        }
      })
      .catch((error) => alert(`Por favor intente de nuevo. Ocurrio un ${error}`))
  }, [userInfo, setUserInfo, toogleMenu, setShowMenuButton])

  useEffect(() => {
    executeExit()
  }, [executeExit])

  return (
    <>
      {closedSession ? <Redirect from="#" to="/landing" /> : ''}
    </>
  )
}

export default Exit
